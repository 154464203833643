import {
  Text,
  useStyleConfig,
  BoxProps,
  Flex,
  ResponsiveValue,
} from '@chakra-ui/react';

import {
  IconTiersCar,
  IconTiersDriver,
  IconTiersRarity,
  IconTournamentTier,
} from 'icons';

import { CarTier, DriverTier, RarityTier, TierType } from 'types';

type TiersProps = {
  tierType: TierType;
  tier: CarTier | DriverTier | RarityTier;
  showTitle?: boolean;
  inlineTitle?: boolean;
  size?: ResponsiveValue<string> | undefined;
  tierColor?: string;
};

type Colors = {
  [key in TierType]: {
    [key in CarTier | DriverTier | RarityTier]?: string;
  };
};

export const defaultColors = {
  free: 'teal.100',
  trial: 'teal.100',
  bronze: 'alexandria.100',
  silver: 'white.100',
  gold: 'cowbell.100',
  platinum: 'skyDancer.100',
  common: 'white.80',
  uncommon: 'uncommon.100',
  rare: 'rare.100',
  epic: 'epic.100',
  legendary: 'legendary.100',
};

export const colors: Colors = {
  race: defaultColors,
  car: defaultColors,
  driver: defaultColors,
  rarity: defaultColors,
};

const RTiers = ({
  tierType,
  tier,
  showTitle = false,
  inlineTitle = false,
  size,
  tierColor,
  ...rest
}: BoxProps & TiersProps) => {
  const styles = useStyleConfig('Tiers', { size });

  const color = tierColor
    ? tierColor
    : tier
    ? colors[tierType][tier.toLowerCase() as CarTier]
    : '';

  // color gets applied to path children within the svg element
  const style = {
    '& path': {
      stroke: color,
    },
  };

  return (
    <Flex
      __css={styles}
      {...rest}
      flexDirection={inlineTitle ? 'row' : { base: 'column', md: 'row' }}
    >
      {tierType === 'car' && <IconTiersCar sx={style} />}
      {tierType === 'driver' && <IconTiersDriver sx={style} />}
      {tierType === 'rarity' && (
        <>
          {tier === 'Tournament' ? (
            <IconTournamentTier />
          ) : (
            <IconTiersRarity sx={style} />
          )}
        </>
      )}

      {showTitle && (
        <Text
          className="title"
          color={color}
          opacity={0.8}
          textTransform="uppercase"
          fontFamily="heading"
        >
          {tier}
        </Text>
      )}
    </Flex>
  );
};

export default RTiers;
