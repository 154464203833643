import {
  Button,
  Flex,
  Heading,
  Text,
  ToastId,
  UseDisclosureProps,
  useToast,
} from '@chakra-ui/react';
import { RDrawer, RImage, RLoadingAnimation, RToast } from 'components';
import { useAnimatedTransition } from 'context';
import { useGetTicketsAvailable } from 'hooks';
import useRenewTrialCar from 'hooks/TrialCar/useRenewTrialCar';
import {
  IconCollect,
  IconGameplay,
  IconPriceTag,
  IconRenew,
  IconRetain,
  IconSearch,
  IconTicket,
  IconUnlockedUpgrade,
  IconUpgrade,
} from 'icons';
import { useRef } from 'react';

type RenewDrawerProps = UseDisclosureProps;

const RenewItem = ({
  icon,
  title,
}: {
  icon: React.ReactNode;
  title: string;
}) => {
  return (
    <Flex
      background="linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)"
      borderTopLeftRadius="4px"
      borderBottomLeftRadius="4px"
      alignItems="center"
      px="2"
      py="1"
      gap="2"
    >
      {icon}
      <Text
        fontStyle="italic"
        fontWeight="600"
        fontSize={{ base: '12px', md: '14px' }}
      >
        {title}
      </Text>
    </Flex>
  );
};

const RenewDrawer = ({
  isOpen = false,
  onClose = () => null,
}: RenewDrawerProps) => {
  const { data } = useGetTicketsAvailable();
  const { mutateAsync, isSuccess, isLoading } = useRenewTrialCar();

  const toastIdRef = useRef<ToastId>();
  const toast = useToast();

  const { showAnimatedTransition } = useAnimatedTransition();

  const formatErrorMessage = (errorMessage: string) => {
    const braceIndex = errorMessage.indexOf('{');
    if (braceIndex > -1) {
      return errorMessage.substring(0, braceIndex - 2);
    }

    return errorMessage;
  };

  const handleRenewTrialCar = async () => {
    try {
      await mutateAsync();
      showAnimatedTransition({
        title: 'Trial Car',
        id: 'trial-car-renewed',
        subtitle: 'Renewed',
        color: 'treasuredTeal.100',
        bgColor:
          'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
        gradientColor: {
          from: 'rgba(72, 223, 187, 0.2)',
          to: 'rgba(72, 223, 187, 0)',
        },
      });
      onClose();
    } catch (err) {
      const typedError = err as {
        message?: string;
        response?: { data?: { message?: string } };
      };
      toastIdRef.current = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={formatErrorMessage(
              typedError?.message ||
                typedError?.response?.data?.message ||
                'An error occurred while renewing your trial car'
            )}
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
    }
  };

  return (
    <RDrawer
      heading="Car Renew"
      isOpen={isOpen}
      onClose={onClose}
      variant="upgrade"
      headingUppercase={true}
      headingProps={{
        fontSize: '18px',
        fontWeight: 400,
      }}
      drawerHeaderProps={{
        bg: 'linear-gradient(180deg, #121212 0%, #1E1E1E 100%)',
        borderBottomRadius: '1rem',
        color: 'white.60',
        textTransform: 'none',
      }}
      drawerBodyProps={{
        bg: 'linear-gradient(180deg, rgba(38, 166, 154, 0) 0%, rgba(38, 166, 154, 0.64) 72.48%, rgba(38, 166, 154, 0.16) 100%)',
      }}
      drawerFooterProps={{
        bg: `linear-gradient(180deg, #121212 0%, #1E1E1E 100%)`,
        borderTop: 'none',
        borderTopRadius: '1.25rem',
        mt: '-1rem',
      }}
      drawerBody={
        !isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flex="1 0 0"
            px={{ base: '0', md: '8' }}
            pt={{ base: '2' }}
          >
            <Heading
              fontSize={{ base: '2rem', md: '2.5rem' }}
              fontWeight="400"
              mb={{ base: '1rem', md: '3rem' }}
            >
              Renew Now
            </Heading>
            <Flex
              position="relative"
              zIndex="1"
              justifyContent="center"
              alignItems="center"
              width={{ base: '70%', md: '100%' }}
              mb="2rem"
            >
              <RImage
                src={require(`public/images/cars/livery-free.webp`)}
                alt=""
                priority={true}
                objectPosition="center center"
                position="absolute"
                zIndex="2"
              />

              <RImage
                src={require(`public/images/cars/livery-shadow.webp`)}
                alt=""
                position="relative"
                objectPosition="center center"
                zIndex="0"
                filter="blur(2px)"
              />
            </Flex>
            <Flex flexDirection="column" w="full" alignItems="center" gap="4">
              <IconUnlockedUpgrade />
              <Flex gap="4" flexDirection="row" w="full">
                <Flex flexDirection="column" gap="2" flex="1 0 0">
                  <RenewItem icon={<IconGameplay />} title="Explore Gameplay" />
                  <RenewItem icon={<IconSearch />} title="Discover Potential" />
                  <RenewItem icon={<IconPriceTag />} title="Earn Discounts" />
                </Flex>
                <Flex flexDirection="column" gap="2" flex="1 0 0">
                  <RenewItem icon={<IconCollect />} title="Collect Rewards" />
                  <RenewItem icon={<IconRetain />} title="Retain Progress" />
                  <RenewItem
                    icon={
                      <IconUpgrade
                        __css={{ path: { fill: 'white.100' } }}
                        width="1rem"
                        height="1rem"
                      />
                    }
                    title="Access Upgrades"
                  />
                </Flex>
              </Flex>
              <Text
                fontFamily="heading"
                textTransform="uppercase"
                fontSize={{ base: '16px', md: '18px' }}
              >
                Unlocked Features
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex
            zIndex={0}
            w="full"
            h="full"
            gap="1.5rem"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <RLoadingAnimation />
            <Text fontSize="1rem" color="white.80">
              We&apos;re renewing your car. Please wait a moment.
            </Text>
          </Flex>
        )
      }
      drawerFooter={
        <Flex flexDirection="column" gap="1rem" w="full">
          <Flex w="full" flexDir="column" gap="0.125rem">
            <Flex
              w="full"
              alignItems="center"
              justifyContent="space-between"
              px="0.5rem"
              py="0.25rem"
              borderTopRadius="0.5rem"
              bg="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            >
              <Heading
                fontSize="0.875rem"
                color="white.60"
                textTransform="uppercase"
                fontWeight={400}
              >
                Available
              </Heading>
              <Flex gap="0.5rem" alignItems="center">
                <IconTicket w="1.5rem" h="1.5rem" />
                <Heading
                  fontSize="1.125rem"
                  textTransform="uppercase"
                  color="white.80"
                  fontWeight={400}
                >
                  {data?.ticketsAvailable.ticketsAvailable}
                </Heading>
              </Flex>
            </Flex>

            <Flex
              w="full"
              alignItems="center"
              justifyContent="space-between"
              p="0.5rem"
              bg="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            >
              <Heading
                fontSize="0.875rem"
                color="white.60"
                textTransform="uppercase"
                fontWeight={400}
              >
                Renew Cost
              </Heading>
              <Flex gap="0.5rem" alignItems="center">
                <IconTicket w="1.5rem" h="1.5rem" />
                <Heading
                  fontSize="1.125rem"
                  textTransform="uppercase"
                  color="white.80"
                  fontWeight={400}
                >
                  1
                </Heading>
              </Flex>
            </Flex>
          </Flex>
          <Button
            w="full"
            variant="tertiary"
            borderRadius="0.25rem"
            onClick={handleRenewTrialCar}
            isLoading={isLoading}
            disabled={data?.ticketsAvailable.ticketsAvailable === 0}
          >
            {data?.ticketsAvailable.ticketsAvailable === 0
              ? 'No Tickets Available'
              : 'Renew'}
            {data?.ticketsAvailable.ticketsAvailable &&
              data?.ticketsAvailable.ticketsAvailable > 0 && (
                <IconRenew ml="0.25rem" />
              )}
          </Button>
        </Flex>
      }
    />
  );
};

export default RenewDrawer;
