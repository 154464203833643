import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { raceRarity } from 'components/RacePreviewDrawer/_components/RaceHeader';
import RaceTier from 'components/RaceTier/RaceTier';
import { IconChevron } from 'icons';
import { useQueryState } from 'nuqs';

const DifficultyFilter = () => {
  const [selectedDifficulty, setSelectedDifficulty] = useQueryState(
    'difficulty',
    {
      defaultValue: 'all',
    },
  );

  return (
    <Menu variant="secondary">
      <MenuButton
        as={Button}
        rightIcon={
          <IconChevron __css={{ transform: 'rotate(90deg)' }} ml={4} />
        }
        variant="secondary"
        h="34px"
        fontSize="14px"
      >
        <Flex 
          align="center" 
          gap={2}
        >
          <RaceTier difficulty={selectedDifficulty === 'all' ? 0 : Number(selectedDifficulty) as 0 | 1 | 2 | 3 | 4 | 5} 
            padding="5px"
            iconSize="14px"
          />
          {selectedDifficulty === 'all' 
            ? 'All' 
              : raceRarity[Number(selectedDifficulty) as 0 | 1 | 2 | 3 | 4 | 5] + (selectedDifficulty !== '5' ? '+' : '')}
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => setSelectedDifficulty('all')} gap={2}>
        <RaceTier difficulty={0} 
          padding="5px"
          iconSize="14px"
        />
          All
        </MenuItem>
        <MenuItem onClick={() => setSelectedDifficulty('1')} gap={2}>
          <RaceTier difficulty={0} 
            padding="5px"
            iconSize="14px"
          />
          Common+
        </MenuItem>
        <MenuItem onClick={() => setSelectedDifficulty('2')} gap={2}>
          <RaceTier difficulty={2} 
            padding="5px"
            iconSize="14px"
          />
          Uncommon+
        </MenuItem>
        <MenuItem onClick={() => setSelectedDifficulty('3')} gap={2}>
          <RaceTier difficulty={3} 
            padding="5px"
            iconSize="14px"
          />
          Rare+
        </MenuItem>
        <MenuItem onClick={() => setSelectedDifficulty('4')} gap={2}>
          <RaceTier difficulty={4} 
            padding="5px"
            iconSize="14px"
          />
          Epic+
        </MenuItem>
        <MenuItem onClick={() => setSelectedDifficulty('5')} gap={2}>
          <RaceTier difficulty={5} 
            padding="5px"
            iconSize="14px"
          />
          Legendary
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default DifficultyFilter;
