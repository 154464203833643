import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckWhite = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    viewBox="0 0 21 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M19 5.54 8.375 16.163c-.156.195-.39.273-.625.273-.273 0-.508-.078-.664-.273L1.46 10.54a.856.856 0 0 1 0-1.289.855.855 0 0 1 1.289 0l5 4.96 9.96-9.96a.856.856 0 0 1 1.29 0c.39.352.39.938 0 1.29Z"
    />
  </svg>
));
export default SvgIconCheckWhite;
