import { useInfiniteQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetRacesByUserId = () => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const pastRacesGql = graphql(`
    query GetPastRaces($Cursor: Float) {
      getPastRaces(filter: { cursor: $Cursor, limit: 50 }) {
        id
        laps
        prizePool
        distributionStatus
        status
        userInRace

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets
        timestampWithMcs
        difficulty

        airTemperatureCategory
        tarmacConditionCategory
        trackRoughnessCategory
        trackTemperatureCategory
        weatherCategory
        windCategory
        tier {
          name
        }

        playersParticipants {
          id
          position
          status
          time
          points
          earnings

          car {
            id
            name
            livery
            tyresId
            rarity
            tier {
              name
            }
            tyres {
              id
            }
          }

          user {
            id
            email
            avatarId
            playerName
            countryFlag
          }
        }

        track {
          id
          codeName
          frontendName
          length
          description
          has2D
          has3D
        }
      }
    }
  `);

  const getRacesByUserId = useInfiniteQuery({
    enabled: Boolean(hasToken),
    queryKey: ['pastRacesQuery'],
    getNextPageParam: (lastPage: any) => {
      const lastRace = lastPage.getPastRaces[lastPage.getPastRaces.length - 1];
      return lastRace ? lastRace.timestampWithMcs : undefined;
    },
    queryFn: ({ pageParam = 0 }) => {
      return graphQLClient.request(pastRacesGql, {
        Cursor: pageParam,
      });
    },
  });

  return getRacesByUserId;
};

export default useGetRacesByUserId;
