import { Flex, Show, Text } from "@chakra-ui/react";
import { CountdownTimer } from "components/CountdownTimer/CountdownTimer";
import { IconLeaderboard } from "icons";
import { getNextDay3PMEST, getNextFriday3PMEST } from "utils/helpers";

const TimerIcon = () => {
  return (
    <Flex
      bg="linear-gradient(180deg, #121212 0%, #1E1E1E 100%)"
      borderRadius="2px"
      p={{
        base: '4px',
        md: '8px',
      }}
      alignItems="center"
      position="absolute"
      bottom="-2px"
      left="0"
      boxShadow="0px 0px 10px 0px rgba(216, 56, 50, 0.75)"
      _before={{
        content: `""`,
        borderRadius: '2px',
        backgroundImage: 'linear-gradient(270deg, #F6685E 0.56%, #D83832 100%), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));',
        padding: '2px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        zIndex: -1,
      }}
    >
      <IconLeaderboard
        sx={{
          path: {
            fill: 'uncommon.100'
          }
        }}
        width={{
          base: '16px',
          md: '20px',
        }}
        height={{
          base: '16px',
          md: '20px',
        }}
      />
    </Flex>
  )
}

const LeaderboardTimers = () => {
  return (
    <Flex
      // backgroundImage="linear-gradient(90deg, rgba(246, 104, 94, 0.4) 30%, rgba(216, 56, 50, 0.08) 100%), 
      // linear-gradient(90deg, rgba(246, 104, 94, 0.8) 50%, rgba(216, 56, 50, 0) 100%)"
      // style={{
      //   backgroundOrigin: "border-box",
      //   backgroundClip: "padding-box, border-box",
      // }} 
      borderRadius="4px"
      background="linear-gradient(90deg, rgba(246, 104, 94, 0.4) 30%, rgba(216, 56, 50, 0.08) 100%)"
      px={{
        base: '1',
        md: '2',
      }}
      py="1"
      gap={{ base: 1, md: '2' }}
      position="relative"
      pl={{
        base: '1',
        md: '2.75rem',
      }}
      border="1.5px solid rgba(246, 104, 94, 0.8)"
      alignItems="center"
      justifyContent="center"
      // border="2px solid transparent"
      // _before={{
      //   content: `""`,
      //   borderRadius: '4px',
      //   backgroundImage: 'linear-gradient(90deg, rgba(246, 104, 94, 0.8) 30%, rgba(216, 56, 50, 0) 100%)',
      //   position: 'absolute',
      //   inset: '0',
      //   margin: '2.5px',
      //   zIndex: -1,
      // }}
    >
      <Show above="md">
        <TimerIcon />
      </Show>
      <Flex
        alignItems="center"
        gap="1"
      >
        <Show below="md">
          <Text
            color="uncommon.100"
            fontSize="10px"
            fontWeight="600"
          >
            DAY
          </Text>
        </Show>
        <Show above="md">
          <Text
            color="uncommon.100"
            fontSize="14px"
            fontWeight="600"
          >
            DAILY
          </Text>
        </Show>
        <CountdownTimer timeLeft={getNextDay3PMEST()} />
      </Flex>
      <Flex
        alignItems="center"
        gap="1"
      >
        <Show below="md">
          <Text
            color="uncommon.100"
            fontSize="10px"
            fontWeight="600"
          >
            WEEK
          </Text>
        </Show>
        <Show above="md">
          <Text
            color="uncommon.100"
            fontSize="14px"
            fontWeight="600"
          >
            WEEKLY
          </Text>
        </Show>
        <CountdownTimer timeLeft={getNextFriday3PMEST()} />
      </Flex>
    </Flex>
  )
}

export default LeaderboardTimers;