// used for race entries and results lists
import {
  Text,
  Flex,
  Tag,
  Heading,
  Skeleton,
  FlexProps,
  Button,
  GridItem,
  Grid,
  Box,
} from '@chakra-ui/react';
import { RaceParticipants } from 'api/generated/graphql';
import {
  RCarCard,
  RBaseCardTopLeft,
  RTooltip,
  RTiers,
  RBaseCardTopRight,
} from 'components';
import { AppliedLivery, Livery, RarityTier, Tyres } from 'types';
import {
  IconEmptySet,
  IconEyeHidden,
  IconEyeShow,
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
} from 'icons';
import Flags, { FlagsProps } from 'components/flags';
import { AvatarPicture } from 'components/Avatar/AvatarPicture';
import { useResults } from 'context';
import { BaseCardProps } from 'components/CarCard/CarBaseCard';
import { rarityGradients } from 'utils/themeConstants';

const RaceGrid = ({
  playersParticipants,
  text,
  gridType,
  carCardProps,
}: {
  playersParticipants: RaceParticipants[];
  text: string;
  gridType: 'entries' | 'results';
  carCardProps?: BaseCardProps & FlexProps;
}) => {
  const { showAllResults, setShowAllResults } = useResults();
  const filtered = playersParticipants?.filter((playersParticipant) => {
    return playersParticipant?.status !== 'rejected'; // possible values are processing, accepted, rejected
  });

  const sorted =
    gridType === 'entries'
      ? filtered.sort((a, b) => {
        if (a?.qualifyingGridPosition && b?.qualifyingGridPosition) {
          return a?.qualifyingGridPosition - b?.qualifyingGridPosition; // Sort by qualifying grid position for entries
        }
        return 0;
      })
      : filtered.sort((a, b) => {
        if (a?.position && b?.position) {
          return a.position - b.position; // Sort by position for results
        }
        return 0;
      })
  const tyreIcons: Record<Tyres, JSX.Element> = {
    C1: <IconTyreC1 h="2rem" w="auto" />,
    C2: <IconTyreC2 h="2rem" w="auto" />,
    C3: <IconTyreC3 h="2rem" w="auto" />,
    C4: <IconTyreC4 h="2rem" w="auto" />,
    C5: <IconTyreC5 h="2rem" w="auto" />,
    INTER: <IconTyreInter h="2rem" w="auto" />,
    WET: <IconTyreWet h="2rem" w="auto" />,
  };
  return (
    <Flex flexDirection="column">
      <Text mb="6" fontSize="md" color="white.60">
        {text}
      </Text>
      {gridType === 'results' && (
        <Button
          variant="secondary"
          w="fit-content"
          mb="6"
          alignSelf="center"
          onClick={() => setShowAllResults((oldState) => !oldState)}
        >
          {showAllResults ? 'Hide' : 'Show'} Results{' '}
          {showAllResults ? (
            <IconEyeShow ml="0.5rem" />
          ) : (
            <IconEyeHidden ml="0.5rem" />
          )}
        </Button>
      )}
      {!showAllResults && sorted.length === 0 && (
        <Flex
          width="full"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <IconEmptySet mb="1rem" />
          <Heading as="h2" size="md" textTransform="uppercase">
            No Entries Yet
          </Heading>
        </Flex>
      )}
      
      <Flex pb="20" flexDirection="column">
        <Grid templateColumns={{ md: 'repeat(2, 1fr)' }} gap={6}>
        {sorted.map((playersParticipant, index) => {
          const { car } = playersParticipant;
            return (
              <GridItem 
              key={index} 
              w="full" 
              >
                <RCarCard
                  key={index}
                  isResults={gridType === 'results'}
                  backgroundPreload
                  playersParticipant={playersParticipant}
                  livery={car?.livery as Livery}
                  appliedLivery={car?.appliedLivery as AppliedLivery}
                  mb="6"
                  h="200px"
                  showResultsFooter={gridType === 'results'}
                  imageProps={{
                    transform: 'scale(70%)',
                    marginBottom: '-1rem',
                  }}
                  {...carCardProps}
                >
                  <RBaseCardTopLeft>
                    <Flex
                      flexDirection="column"
                      gap="10px"
                      justifyContent="center"
                    >
                      <Skeleton isLoaded={showAllResults}>
                        <Flex alignItems="center" gap={['2', '3']}>
                          {(gridType === 'entries' && playersParticipant?.qualifyingGridPosition) ? (
                            <RTooltip label="Grid Position">
                              <Tag bg="white.80" color="black.80">
                                {playersParticipant?.qualifyingGridPosition}
                              </Tag>
                            </RTooltip>
                          ) :
                            (gridType === 'results' && playersParticipant?.position) && (
                              <RTooltip label="Finishing Position">
                                <Tag bg="white.80" color="black.80">
                                  {playersParticipant?.position}
                                </Tag>
                              </RTooltip>
                            )}
                          <Text fontSize={['sm', 'md']} maxW="7rem" noOfLines={1}>
                            {car?.name || 'New Car'}
                          </Text>
                        </Flex>
                      </Skeleton>
                      <Skeleton w="50px" isLoaded={showAllResults}>
                        <Flex alignItems="center" h="1rem" gap="1">
                          {/* <RTiers
                          tierType="car"
                          tier={playersParticipant.tier?.name as CarTier}
                        /> */}
                          <Box
                            background={rarityGradients[playersParticipant?.car?.tier?.name as keyof typeof rarityGradients] || rarityGradients['Free']}
                            borderWidth="1px"
                            borderColor="white.20"
                            borderRadius="0.25rem"
                            p="3px"
                          >
                            <RTiers
                              tier={playersParticipant?.car?.tier?.name as RarityTier}
                              tierType="rarity"
                              __css={{
                              svg: {
                                height: '12px',
                                width: '12px',
                              },
                            }}
                          />
                          </Box>
               
                          {playersParticipant?.tyres?.id && tyreIcons[playersParticipant?.tyres?.id as Tyres]}
                        </Flex>
                      </Skeleton>
                    </Flex>
                  </RBaseCardTopLeft>
                  <RBaseCardTopRight maxW="50%">
                    <Flex alignItems="center" h="1.5rem">
                      <Flags
                        countryCode={
                          showAllResults
                            ? (playersParticipant?.user
                                ?.countryFlag as FlagsProps['countryCode']) ||
                              'default'
                            : 'default'
                        }
                      />
                      <Skeleton
                        isLoaded={showAllResults}
                        ml={showAllResults ? '' : '1rem'}
                      >
                        <AvatarPicture
                          key={playersParticipant.user.avatarId}
                          avatarId={playersParticipant.user.avatarId || ''}
                        />
                      </Skeleton>
                      <Skeleton
                        isLoaded={showAllResults}
                        ml={showAllResults ? '' : '1rem'}
                      >
                        <Text fontSize={['sm', 'md']} noOfLines={1}>
                          {playersParticipant?.user?.playerName || 'Owner Name'}
                        </Text>
                      </Skeleton>
                    </Flex>
                  </RBaseCardTopRight>
                </RCarCard>
              </GridItem>
            );
          })}
        </Grid>
        
      </Flex>
    </Flex>
  );
};
export default RaceGrid;