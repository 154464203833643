import {
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  UseDisclosureProps,
  useToast,
  ToastId,
} from '@chakra-ui/react';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { RDrawer, RImage, RLoadingAnimation, RToast } from 'components';
import { CheckoutModal } from 'components/CheckoutModal/CheckoutModal';
import { CryptoTabCar } from 'components/CheckoutModal/Crypto/CryptoTabCar';
import { CryptoTabTrailer } from 'components/CheckoutModal/Crypto/CryptoTabTrailer';
import { useAnimatedTransition } from 'context';
import { useGetAvailableTrailers, useGetCarUpgradeTrailerPrices } from 'hooks';
import useGetCarPrices from 'hooks/Crypto/useGetCarPrices';
import useScheduleCarUpgrade from 'hooks/TrialCar/useScheduleCarUpgrade';
import useScheduleCarUpgradeDiscount from 'hooks/TrialCar/useScheduleCarUpgradeDiscount';
import {
  IconCheckeredFlag,
  IconGraphics,
  IconHourglass,
  IconMoneyBagUpgrade,
  IconThumbsUpGarage,
  IconUnlockedUpgrade,
  IconUpgrade,
  IconWrenchUpgrade,
} from 'icons';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import routes from 'routes';

interface UpgradeDrawerProps extends UseDisclosureProps {
  maximumTimesUsed: number;
  timesUsed: number;
}

const paymentEnv =
  process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';
const trailersContractId =
  process.env.NEXT_PUBLIC_LOCAL_TRAILERS_CONTRACT_ID || '';
const carContractId = process.env.NEXT_PUBLIC_CARS_CONTRACT_ID || '';

const UpgradeItem = ({
  icon,
  title,
}: {
  icon: React.ReactNode;
  title: string;
}) => {
  return (
    <Flex
      background="linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)"
      borderTopLeftRadius="4px"
      borderBottomLeftRadius="4px"
      alignItems="center"
      px="2"
      py="1"
      gap="2"
    >
      {icon}
      <Text
        fontStyle="italic"
        fontWeight="600"
        fontSize={{ base: '12px', md: '14px' }}
      >
        {title}
      </Text>
    </Flex>
  );
};

const UpgradeDrawer = ({
  isOpen = false,
  onClose = () => null,
  maximumTimesUsed,
  timesUsed,
}: UpgradeDrawerProps) => {
  const { data: trailerPrices } = useGetCarUpgradeTrailerPrices();
  const { data: carPrices } = useGetCarPrices();
  const { refetch: refetchTrailers } = useGetAvailableTrailers(
    undefined,
    'Local'
  );
  const { mutateAsync: scheduleCarUpgrade } = useScheduleCarUpgrade();
  const { mutateAsync: scheduleCarUpgradeDiscount } =
    useScheduleCarUpgradeDiscount();
  const router = useRouter();

  const {
    isOpen: isCheckoutModalOpen,
    onOpen: onOpenCheckoutModal,
    onClose: onCloseCheckoutModal,
  } = useDisclosure();
  const {
    isOpen: isCheckoutDiscountModalOpen,
    onOpen: onOpenCheckoutDiscountModal,
    onClose: onCloseCheckoutDiscountModal,
  } = useDisclosure();
  const { showAnimatedTransition } = useAnimatedTransition();
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);

  const onSuccessBuy = async (e: CrossmintEvent) => {
    try {
      setIsLoadingUpgrade(true);
      onCloseCheckoutModal();
      const getTrailerWithRetry = async (
        maxAttempts = 10,
        delayMs = 2000
      ): Promise<{ success: boolean; referenceId?: string }> => {
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
          const result = await refetchTrailers();
          const trailer = result.data?.getAvailableTrailers[0];

          if (trailer) {
            const res = await scheduleCarUpgrade({
              tokenId: trailer.tokenId,
            });
            setIsLoadingUpgrade(false);
            return {
              success: true,
              referenceId: res.scheduleCarUpgrade.referenceId,
            };
          }

          // Espera antes da próxima tentativa
          await new Promise((resolve) => setTimeout(resolve, delayMs));
        }
        return { success: false };
      };

      const { success, referenceId } = await getTrailerWithRetry();

      if (!success) {
        throw new Error('Failed to get trailer after multiple attempts');
      }

      showAnimatedTransition({
        title: 'Car',
        id: 'trailer-purchased-success',
        subtitle: 'Upgraded',
        color: 'treasuredTeal.100',
        bgColor:
          'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
        gradientColor: {
          from: 'rgba(72, 223, 187, 0.2)',
          to: 'rgba(72, 223, 187, 0)',
        },
      });
      router.push(routes.marketplaceTrailers(referenceId || '', 'Upgrade'));
    } catch (error) {
      toastIdRef.current = toast({
        position: 'top',
        render: () => (
          <RToast
            variant="error"
            title="Upgrade failed"
            description="Please try again later, or contact support if the issue persists."
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
    } finally {
      setIsLoadingUpgrade(false);
    }
  };

  const onSuccessBuyDiscount = async (e: CrossmintEvent) => {
    try {
      setIsLoadingUpgrade(true);
      onCloseCheckoutDiscountModal();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const orderIdentifier = e?.payload?.orderIdentifier;

      const res = await scheduleCarUpgradeDiscount({
        tokenId: orderIdentifier,
      });

      showAnimatedTransition({
        title: 'Car',
        id: 'trailer-purchased-success',
        subtitle: 'Upgraded',
        color: 'treasuredTeal.100',
        bgColor:
          'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
        gradientColor: {
          from: 'rgba(72, 223, 187, 0.2)',
          to: 'rgba(72, 223, 187, 0)',
        },
      });
      router.push(
        routes.marketplaceTrailers(
          res.scheduleCarUpgradeDiscount.referenceId || '',
          'UpgradeDiscount'
        )
      );
    } catch (error) {
      toastIdRef.current = toast({
        position: 'top',
        render: () => (
          <RToast
            variant="error"
            title="Upgrade failed"
            description="Please try again later, or contact support if the issue persists."
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
    } finally {
      setIsLoadingUpgrade(false);
    }
  };

  return (
    <>
      <RDrawer
        heading="Car Upgrade"
        isOpen={isOpen}
        onClose={onClose}
        variant="upgrade"
        headingUppercase={true}
        headingProps={{
          fontSize: '18px',
          fontWeight: 400,
        }}
        drawerHeaderProps={{
          bg: 'linear-gradient(180deg, #121212 0%, #1E1E1E 100%)',
          borderBottomRadius: '1rem',
          color: 'white.60',
          textTransform: 'none',
        }}
        drawerBodyProps={{
          bg: 'linear-gradient(180deg, rgba(38, 166, 154, 0) 0%, rgba(38, 166, 154, 0.64) 72.48%, rgba(38, 166, 154, 0.16) 100%)',
        }}
        drawerFooterProps={{
          bg: `linear-gradient(180deg, #121212 0%, #1E1E1E 100%)`,
          borderTop: 'none',
          borderTopRadius: '1.25rem',
          mt: '-1rem',
        }}
        drawerBody={
          !isLoadingUpgrade ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              flex="1 0 0"
              px={{ base: '0', md: '8' }}
              pt={{ base: '2' }}
            >
              <Heading
                fontSize={{ base: '2rem', md: '2.5rem' }}
                fontWeight="400"
                mb={{ base: '1rem', md: '3rem' }}
              >
                Upgrade Now
              </Heading>
              <Flex
                position="relative"
                zIndex="1"
                w={{ base: '70%', md: '100%' }}
                justifyContent="center"
                alignItems="center"
                mb="2rem"
              >
                <RImage
                  src={require(`public/images/cars/livery-free.webp`)}
                  alt=""
                  priority={true}
                  objectPosition="center center"
                  position="absolute"
                  zIndex="2"
                />

                <RImage
                  src={require(`public/images/cars/livery-shadow.webp`)}
                  alt=""
                  position="relative"
                  objectPosition="center center"
                  zIndex="0"
                  filter="blur(2px)"
                />
              </Flex>
              <Flex flexDirection="column" w="full" alignItems="center" gap="4">
                <IconUnlockedUpgrade />
                <Flex gap="4" flexDirection="row" w="full">
                  <Flex flexDirection="column" gap="2" flex="1 0 0">
                    <UpgradeItem
                      icon={<IconGraphics />}
                      title="Stats & Insights"
                    />
                    <UpgradeItem
                      icon={<IconThumbsUpGarage />}
                      title="Track Suggestions"
                    />
                    <UpgradeItem
                      icon={<IconMoneyBagUpgrade />}
                      title="Progressive Access"
                    />
                  </Flex>
                  <Flex flexDirection="column" gap="2" flex="1 0 0">
                    <UpgradeItem
                      icon={<IconWrenchUpgrade />}
                      title="Tuning Analysis"
                    />
                    <UpgradeItem
                      icon={<IconHourglass />}
                      title="Fusion Capability"
                    />
                    <UpgradeItem
                      icon={<IconCheckeredFlag width="1rem" height="1rem" />}
                      title="Higher Limits"
                    />
                  </Flex>
                </Flex>
                <Text
                  fontFamily="heading"
                  textTransform="uppercase"
                  fontSize={{ base: '16px', md: '18px' }}
                >
                  Unlocked Features
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex
              zIndex={0}
              w="full"
              h="full"
              gap="1.5rem"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <RLoadingAnimation />
              <Text fontSize="1rem" color="white.80">
                We&apos;re upgrading your car. Please wait a moment.
              </Text>
            </Flex>
          )
        }
        drawerFooter={
          <Flex flexDirection="column" gap="1rem" w="full">
            <Flex
              w="full"
              alignItems="center"
              justifyContent="space-between"
              px="0.5rem"
              py="0.25rem"
              borderTopRadius="0.5rem"
              bg="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            >
              <Heading
                fontSize="0.875rem"
                color="white.60"
                textTransform="uppercase"
                fontWeight={400}
              >
                Upgrade Cost
              </Heading>
              <Flex gap="0.5rem" alignItems="center">
                <Text
                  fontSize="1.125rem"
                  textTransform="uppercase"
                  color="treasuredTeal.100"
                  fontWeight={600}
                >
                  {timesUsed >= maximumTimesUsed ? '$99.99' : '$149.99'}
                </Text>
              </Flex>
            </Flex>
            <Button
              w="full"
              variant="tertiary"
              borderRadius="0.25rem"
              onClick={() =>
                timesUsed >= maximumTimesUsed
                  ? onOpenCheckoutDiscountModal()
                  : onOpenCheckoutModal()
              }
            >
              Upgrade
              <IconUpgrade
                ml="0.25rem"
                __css={{
                  width: '1.25rem',
                  height: '1.25rem',
                  path: {
                    fill: 'white.100',
                  },
                }}
              />
            </Button>
          </Flex>
        }
      />
      <CheckoutModal
        collectionId={trailersContractId}
        isOpen={isCheckoutModalOpen}
        environment={paymentEnv}
        projectId={projectId}
        checkoutValue="1"
        mintConfig={{
          totalPrice: (
            (trailerPrices?.getCarUpgradeTrailerPrices?.price || 0) / 1e20
          ).toString(),
          quantity: 1,
        }}
        CryptoTab={CryptoTabTrailer}
        cryptoTabProps={{
          onSuccess: (e) => onSuccessBuy(e),
        }}
        onClose={onCloseCheckoutModal}
        title="Upgrade Car"
        onSuccessfulPurchase={(e) => onSuccessBuy(e)}
      />
      <CheckoutModal
        collectionId={carContractId}
        isOpen={isCheckoutDiscountModalOpen}
        environment={paymentEnv}
        projectId={projectId}
        checkoutValue="1"
        mintConfig={{
          totalPrice: (carPrices?.getCarPrice?.price || 0).toString(),
          quantity: 1,
        }}
        CryptoTab={CryptoTabCar}
        cryptoTabProps={{
          onSuccess: (e) => onSuccessBuyDiscount(e),
        }}
        onClose={onCloseCheckoutDiscountModal}
        title="Upgrade Car"
        onSuccessfulPurchase={(e) => onSuccessBuyDiscount(e)}
      />
    </>
  );
};

export default UpgradeDrawer;
