/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, Dispatch, SetStateAction, useState, useMemo } from 'react';

import {
  RaceTuningInput,
  RaceParticipants,
  Races,
  TunableFields,
} from 'api/generated/graphql';
import { RAlert } from 'components';
import { PartTypes, SetupSuggestions, Tyres } from 'types';
import { useTuning } from 'context';
import { useGetRaceById, useTuningForRace, useUserSettings } from 'hooks';
import { PartSlider } from 'components/PartInfo/PartSlider';

export interface EngineAndTuningProps {
  tuningSelections: RaceTuningInput;
  setTuningSelections: Dispatch<SetStateAction<RaceTuningInput>>;
  parts: RaceParticipants;
  setupSuggestions: SetupSuggestions;
  selectedTyres: Tyres;
  tuningError: unknown;
  carId: string;
}

const getPartName = (name: string) => {
  return (
    name?.charAt(0)?.toLowerCase() +
      name?.replace(/\s+/g, '').slice(1) +
      'Tuning' || ''
  );
};

const EngineAndTuning = ({
  tuningSelections,
  setTuningSelections,
  parts,
  setupSuggestions,
  selectedTyres,
  tuningError,
  carId,
}: EngineAndTuningProps) => {
  const {
    setBrakeCoolingTuning,
    setEngineCoolingTuning,
    setFrontWingTuning,
    setRearWingTuning,
    setTransmissionTuning,
    setEngineModeTuning,
    setSuspensionRideHeightTuning,
    setSuspensionStiffnessTuning,
  } = useTuning();

  const [sliderValues, setSliderValues] = useState<Record<string, number>>({});

  const { getUserSettings } = useUserSettings();

  const { data: raceByIdData, isLoading: isLoadingRaceById } = useGetRaceById({
    raceId: tuningSelections.raceId,
  });
  const race = raceByIdData?.getRaceById?.race as Races;
  const userRaceData = race?.playersParticipants?.find((player) => {
    const isPlayerMatch = player.user.id === getUserSettings.data?.getUser.id;
    const isCarMatch =
      !carId ||
      player.car.id === carId ||
      player.car.id === tuningSelections.carId;

    return isPlayerMatch && isCarMatch;
  });
  const tunableFields = raceByIdData?.getRaceById?.tunableFields.filter((field) => field.tunableForRace);


  useEffect(() => {
    setBrakeCoolingTuning(userRaceData?.brakeCoolingTuning || 50);
    setEngineCoolingTuning(userRaceData?.engineCoolingTuning || 50);
    setFrontWingTuning(userRaceData?.frontWingTuning || 50);
    setRearWingTuning(userRaceData?.rearWingTuning || 50);
    setTransmissionTuning(userRaceData?.transmissionTuning || 50);
    setEngineModeTuning(userRaceData?.engineModeTuning || 50);
    setSuspensionRideHeightTuning(userRaceData?.suspensionRideHeightTuning || 50);
    setSuspensionStiffnessTuning(userRaceData?.suspensionStiffnessTuning || 50);
  }, [userRaceData]);

  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning, isLoading: isLoadingTuningForRace } =
    tuningForRace;

  const partsForTuning = useMemo(() => tunableFields?.filter((field) => field), [tunableFields]);

  const defaultValues: Partial<Record<PartTypes, number>> = {};
  partsForTuning && partsForTuning.forEach((part) => {
    defaultValues[part?.name as PartTypes] = Number(
      tuningSelections[`${part?.name}Tuning` as keyof RaceTuningInput]
    );
  });

  const handleSliderOnChangeEnd = async (tunedPart: {
    name: string;
    val: number;
  }) => {
    try {
      setTuningSelections((selections) => {
        return {
          ...selections,
          [tunedPart.name]: tunedPart.val,
        };
      });

      await mutateTuning({
        raceTuningInput: {
          ...tuningSelections,
          [tunedPart.name]: tunedPart.val,
        },
      });

      switch (tunedPart.name) {
        case 'brakeCoolingTuning':
          setBrakeCoolingTuning(tunedPart.val);
          break;
        case 'engineCoolingTuning':
          setEngineCoolingTuning(tunedPart.val);
          break;
        case 'frontWingTuning':
          setFrontWingTuning(tunedPart.val);
          break;
        case 'rearWingTuning':
          setRearWingTuning(tunedPart.val);
          break;
        case 'transmissionTuning':
          setTransmissionTuning(tunedPart.val);
          break;
        case 'engineModeTuning':
          setEngineModeTuning(tunedPart.val);
          break;
        case 'suspensionRideHeightTuning':
          setSuspensionRideHeightTuning(tunedPart.val);
          break;
        case 'suspensionStiffnessTuning':
          setSuspensionStiffnessTuning(tunedPart.val);
          break;
        default:
          break;
      }
    } catch (error) {
      setTuningSelections((selections) => {
        return {
          ...selections,
          [tunedPart.name]: sliderValues[tunedPart.name],
        };
      });
      console.error('Error saving tuning values:', error);
    }
  };

  const tuningRecommendation = setupSuggestions?.[selectedTyres] || {};
  const tuningEdgeThreshold = 2;

  const getMinSuggestion = (part: TunableFields): number => {
    const tuningKey = `${getPartName(part?.name || '')}MinimumGreen`;
    const suggestionValue =
      (part?.name &&
        tuningRecommendation[tuningKey as keyof typeof tuningRecommendation]) ||
      0;
    let suggestion = suggestionValue;
    if (suggestionValue <= (part.lowSettingRange as number)) {
      suggestion = part.lowSettingRange as number;
    }
    if (
      suggestionValue >=
      (part.highSettingRange as number) - tuningEdgeThreshold
    ) {
      suggestion = (part.highSettingRange as number) - tuningEdgeThreshold;
    }
    return suggestion;
  };

  const getMaxSuggestion = (part: TunableFields): number => {
    const tuningKey = `${getPartName(part?.name || '')}MaximumGreen`;
    const suggestionValue =
      (part?.name &&
        tuningRecommendation[tuningKey as keyof typeof tuningRecommendation]) ||
      0;
    let suggestion = suggestionValue;
    if (
      suggestionValue <=
      (part.lowSettingRange as number) + tuningEdgeThreshold
    ) {
      suggestion = (part.lowSettingRange as number) + tuningEdgeThreshold;
    }
    if (suggestionValue >= (part.highSettingRange as number)) {
      suggestion = part.highSettingRange as number;
    }
    return suggestion;
  };

  useEffect(() => {
    const newSliderValues: Record<string, number> = {};
    let hasChanges = false;

    if (!partsForTuning) return;

    partsForTuning.forEach((part) => {
      if (part?.name) {
        const tuningKey = `${getPartName(
          part.name
        )}` as keyof RaceTuningInput;
        const newValue = tuningSelections[tuningKey] !== undefined ? (tuningSelections[tuningKey] as number) : 50;
        if (sliderValues[tuningKey] !== newValue) {
          newSliderValues[tuningKey] = newValue;
          hasChanges = true;
        } else {
          newSliderValues[tuningKey] = sliderValues[tuningKey];
        }
      }
    });

    if (hasChanges) {
      setSliderValues(newSliderValues);
    }
  }, [tuningSelections, partsForTuning, sliderValues]);

  return (
    <Flex w="full" justifyContent="center">
      {Boolean(tuningError) && (
        <RAlert
          variant="error"
          description="There was an error saving tuning values."
          mt="8"
        />
      )}

      <Grid gap="1rem" gridTemplateColumns="repeat(2, 1fr)">
        {partsForTuning &&
          partsForTuning.map((part, index) => {
            return (
              <GridItem
                key={index}
                w="full"
                cursor={
                  isLoadingTuningForRace || isLoadingRaceById
                    ? 'not-allowed'
                    : 'auto'
                }
              >
                <PartSlider
                  part={part as TunableFields}
                  userRaceData={userRaceData}
                  getMaxSuggestion={getMaxSuggestion}
                  getMinSuggestion={getMinSuggestion}
                  getPartName={getPartName}
                  handleSliderOnChangeEnd={handleSliderOnChangeEnd}
                  sliderValue={
                    part && getPartName(part.name) in sliderValues ? sliderValues[getPartName(part.name)] : 50
                  }
                  isLoading={isLoadingTuningForRace || isLoadingRaceById}
                />
              </GridItem>
            );
          })}
      </Grid>
    </Flex>
  );
};

export default EngineAndTuning;