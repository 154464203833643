import {
  Box,
  Modal as ChakraModal,
  Flex,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import TournamentBadge from 'components/Tiers/TournamentBadge';
import { IconGoldenModal } from 'icons';
import { useEffect, useRef } from 'react';

interface GoldenTicketModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGoToTournament: () => void;
  earnedTickets: number;
}

const GoldenTicketModal = ({
  isOpen,
  onClose,
  onGoToTournament,
  earnedTickets,
}: GoldenTicketModalProps) => {
  const explosionVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isOpen && explosionVideoRef.current) {
      explosionVideoRef.current.currentTime = 0;
      explosionVideoRef.current.play();
    }
  }, [isOpen]);

  return (
    <ChakraModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="2xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent 
        p={0} 
        borderRadius="0.5rem" 
        position="relative" 
        overflow="hidden"
      >
        <ModalHeader 
            zIndex={100}
            background="black.100"
            borderTopLeftRadius="0.5rem"
            borderTopRightRadius="0.5rem"
          >
            <Flex
              alignItems="center"
              gap={2}
              pl={2}
            >
              <TournamentBadge />
              <Text 
                textTransform="none"
                fontSize="16px"
                color="white.80"
              >
                Golden Ticket
              </Text>
            </Flex>
            <ModalCloseButton 
              onClick={() => onClose()}
              background="white.10"
              border="none"
              height="100%"
              borderTopRightRadius="0.5rem"
            />
          </ModalHeader>
          <ModalBody 
            p={0} 
            flexDir="column" 
            borderBottomLeftRadius="0.5rem" 
            borderBottomRightRadius="0.5rem"
            position="relative"
          >
            <Box
              as="video"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              autoPlay
              loop
              muted
              playsInline
              zIndex={0}
              sx={{
                objectFit: 'cover',
              }}
            >
              <source src="videos/background-ticket.webm" type="video/webm" />
            </Box>

            <Box
              as="video"
              ref={explosionVideoRef}
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              muted
              opacity={0.3}
              playsInline
              zIndex={1}
              sx={{
                objectFit: 'cover',
              }}
            >
              <source src="videos/explosion-ticket.webm" type="video/webm" />
            </Box>
            <Flex 
              w="100%" 
              h="474px" 
              flexDir="column" 
              alignItems="center" 
              justifyContent="space-between"
              p="2rem"
              textAlign="center"
              zIndex={2}
              background="linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, rgba(255, 238, 88, 0.4) 99.99%)"
            >
              <Text
                fontSize="24px"
                lineHeight="29px"
                fontWeight="600"
                fontStyle="italic"
                color="white.95"
                whiteSpace="pre-line"
              >
                {`Congrats on placing Top 20\n on the Leaderboard!`}
              </Text>
              <Text
                fontSize={{ base: '50px', md: '80px' }}
                lineHeight={{ base: '50px', md: '80px' }}
                fontWeight="600"
                background="linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)"
                backgroundClip="text"
                fontStyle="italic"
              >
                You&apos;ve Won!
              </Text>
              <Flex
                alignItems="center"
                gap={1}
                fontSize={{ base: '80px', md: '120px' }}
                lineHeight={{ base: '80px', md: '120px' }}
              >
                <IconGoldenModal />
                {earnedTickets}
              </Flex>
              <Text
                fontSize="24px"
                lineHeight="29px"
                fontWeight="600"
                fontStyle="italic"
                color="white.95"
                whiteSpace="pre-line"
              >
                {'Head over to Racing to join\n the Tournament before it closes.'}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter
            background="darkVoid.100"
            p={4}
            zIndex={100}
            gap={3}
          >
            <Button
              onClick={() => onClose()}
              color="white"
              fontSize="16px"
              borderRadius="4px"
              w="fit-content"
              px={12}
              variant="secondary"
            >
              NOT RIGHT NOW
            </Button>
            <Button
              onClick={() => {
                onGoToTournament();
                onClose();
              }}
              color="white"
              fontSize="16px"
              borderRadius="4px"
              w="full"
              variant="tertiary"
            >
              LET&apos;S GO!
            </Button>
          </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default GoldenTicketModal;
