import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  ImageProps,
  useTheme,
} from '@chakra-ui/react';
import { useState } from 'react';

import { RBaseCardGradient, RGradientBorder, RImage } from 'components';
import { RarityTier } from 'types';
import { IconLock } from 'icons';
import { useResults } from 'context';

const CarLayer = ({
  backgroundColor,
  backgroundSrc,
  backgroundPreload,
  imageProps,
  isResults,
}: BoxProps & {
  backgroundSrc?: string;
  backgroundPreload?: boolean;
  imageProps?: ImageProps;
  isResults?: boolean;
}) => {
  const { showAllResults } = useResults();
  return (
    <Flex
      position="relative"
      zIndex="1"
      background={backgroundColor}
      justifyContent="center"
      alignItems="center"
      h="full"
    >
      <RImage
        src={
          isResults && !showAllResults
            ? require(`public/images/cars/livery-free.webp`)
            : backgroundSrc
        }
        alt=""
        priority={backgroundPreload}
        objectPosition="center center"
        position="absolute"
        zIndex="2"
        {...imageProps}
      />

      <RImage
        src={require(`public/images/cars/livery-shadow.webp`)}
        alt=""
        position="relative"
        objectPosition="center center"
        zIndex="0"
        filter="blur(2px)"
        {...imageProps}
      />
    </Flex>
  );
};

const ContentLayer = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex position="absolute" zIndex="2" width="full" height="full">
      {children}
    </Flex>
  );
};

enum rarityTierColorLookup {
  common = 'yellow',
  uncommon = 'orange',
  rare = 'yellow',
  epic = 'blue',
  legendary = 'violet',
  trial = 'grey',
  Tournament = 'yellow',
}

const getRarityColor = (rarity: RarityTier | undefined) => {
  if (rarity) return rarityTierColorLookup[rarity];
  else return 'grey';
};

export type BaseCardProps = {
  insetBottomBorderColor?: string;
  outsetSideBorderColor?: string | null;
  backgroundSrc?: string;
  backgroundPreload?: boolean;
  imageProps?: ImageProps;
  backgroundProps?: FlexProps;
  rarity?: RarityTier;
  hasBackground?: boolean;
  hasInsetBorder?: boolean;
  locked?: boolean;
  isResults?: boolean;
  isIndividualGarage?: boolean;
};

const CarBaseCard = ({
  children,
  insetBottomBorderColor,
  outsetSideBorderColor,
  backgroundColor,
  backgroundSrc,
  backgroundPreload, // use when the image is likely to appear "above the fold"
  imageProps,
  backgroundProps,
  rarity,
  hasBackground,
  hasInsetBorder,
  locked,
  isResults,
  isIndividualGarage,
  onClick = undefined,
  ...rest
}: BaseCardProps & BoxProps) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box
      position="relative"
      w="full"
      onClick={onClick}
      onMouseEnter={() => {
        if (onClick) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (onClick) {
          setIsHovering(false);
        }
      }}
      cursor={onClick ? 'pointer' : 'default'}
      {...rest}
    >
      {locked && !isIndividualGarage && (
        <RGradientBorder color={theme.colors.bloodMoon[100]}>
          <Box
            position="absolute"
            top="0"
            right="0"
            w="2.5rem"
            h="2.5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconLock
              pos="absolute"
              zIndex={5}
              w="1rem"
              h="auto"
              top="0.65rem"
              right="0.65rem"
            />

            <Box
              zIndex={4}
              pos="absolute"
              width="100%"
              height="100%"
              bg={theme.colors.bloodMoon[100]}
              clipPath="polygon(100% 0, 100% 100%, 50% 100%, 0 50%, 0 0)"
            />
          </Box>

          <Box
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            width="0.25rem"
            background={`linear-gradient(to bottom, ${theme.colors.alexandria[100]} 0%, transparent 100%)`}
            zIndex={5}
          />
        </RGradientBorder>
      )}

      <ContentLayer>{children}</ContentLayer>

      <CarLayer
        imageProps={imageProps}
        backgroundColor={backgroundColor}
        backgroundSrc={backgroundSrc}
        isResults={isResults}
      />

      {hasBackground && (
        <RBaseCardGradient
          isHovering={isHovering}
          hasInsetBorder={hasInsetBorder}
          gradientColor={getRarityColor(rarity)}
          backgroundProps={backgroundProps}
          zIndex="0"
        />
      )}
    </Box>
  );
};

export default CarBaseCard;
