import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPlayIrp = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      d="m7.125 4.625 4.5 2.75A.75.75 0 0 1 12 8a.762.762 0 0 1-.375.656l-4.5 2.75a.83.83 0 0 1-.375.094.74.74 0 0 1-.75-.75v-5.5a.76.76 0 0 1 .75-.75c.125 0 .25.063.375.125ZM8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 14.5c3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5-3.594 0-6.5 2.938-6.5 6.5 0 3.594 2.906 6.5 6.5 6.5Z"
    />
  </svg>
));
export default SvgIconPlayIrp;
