import React, { ComponentProps } from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import { colors } from 'utils/themeConstants';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
export type Dataset = {
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  pointBorderColor: string;
  pointBackgroundColor?: string;
  pointBorderWidth: number;
  pointRadius: number;
};

const RSpiderChart = ({ ...rest }: ComponentProps<typeof Radar>) => {
  return (
    <Radar
      {...rest}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            bodyFont: {
              family: '"Chakra Petch", sans-serif',
            },
          },
        },
        scales: {
          r: {
            grid: {
              color: colors.glideTime[100],
            },
            angleLines: {
              color: colors.glideTime[100],
            },
            ticks: {
              display: false,
            },
            pointLabels: {
              color: colors.white[60],
              font: {
                family: '"Chakra Petch", sans-serif',
                weight: '500',
                size: 14,
              },
            },
          },
        },
      }}
    />
  );
};

export default RSpiderChart;
