import { useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { IconArrow } from 'icons';
import { guideButtonGradient } from 'utils/themeConstants';

type Content = {
  title: string;
  description: string;
};

interface GuideButtonProps extends FlexProps {
  contents: Content[];
  itemsPerPage?: number;
  overlay?: boolean;
}

const MotionFlex = motion(Flex);

export const GuideButton = ({
  contents = [
    {
      title: 'Header',
      description:
        'Sed posuere consectetur est at lobortis. Aenean lacinia bibendum nulla sed consectetur.',
    },
  ],
  itemsPerPage: desktopItemsPerPage = 2,
  overlay = false,
  ...props
}: GuideButtonProps) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const isMobileAndOpen = isMobile && isOpen;
  const itemsPerPage = isMobile ? 5 : desktopItemsPerPage;

  const numberOfPages = Math.ceil(contents.length / itemsPerPage);

  const currentPageContents = contents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const guideContent = (
    <MotionFlex
      flexDir="column"
      pos={overlay && isMobile && !isOpen ? 'relative' : 'fixed'}
      ml={isMobileAndOpen ? '0' : isMobile ? 'auto' : '1rem'}
      bottom={isMobileAndOpen ? '0' : '1rem'}
      right={isMobileAndOpen ? '0' : '1rem'}
      top={isMobileAndOpen ? '0' : isMobile ? 'auto' : 'auto'}
      left={isMobileAndOpen ? '0' : 'auto'}
      rounded={isMobileAndOpen ? 'none' : '0.25rem'}
      bg="caviar.100"
      roundedTopLeft={isMobileAndOpen ? 'none' : '1.25rem'}
      boxShadow={
        isMobileAndOpen
          ? 'none'
          : '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)'
      }
      width={isMobileAndOpen ? '100vw' : isOpen ? '21.5rem' : '7.75rem'}
      height={isMobileAndOpen ? '100vh' : 'auto'}
      transition={{ duration: 0.2 }}
      animate={{
        width: isMobileAndOpen ? '100vw' : isOpen ? '21.5rem' : '7.75rem',
        height: isMobileAndOpen ? '100vh' : 'auto',
      }}
      zIndex={10}
      {...props}
    >
      <Button
        variant="primary"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="1.25rem"
        py="0.875rem"
        gap="0.5rem"
        rounded="0.25rem"
        roundedTopLeft="1.25rem"
        w="full"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Heading
          fontSize="1rem"
          lineHeight="1rem"
          color="white.100"
          fontWeight={400}
        >
          Guide
        </Heading>
        <IconArrow
          w="1.25rem"
          h="1.25rem"
          transform={isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}
          transition="transform 0.2s ease-in-out"
        />
      </Button>
      <MotionFlex
        flexDir="column"
        w="full"
        overflow="hidden"
        roundedBottom={isMobileAndOpen ? 'none' : '0.25rem'}
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0,
        }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        {currentPageContents.map((content, index) => (
          <Flex
            flexDir="column"
            gap="0.5rem"
            w={isMobileAndOpen ? 'full' : '19.5rem'}
            p="1rem"
            key={index}
          >
            <Heading
              fontSize="1.125rem"
              lineHeight="1.5rem"
              color="white.100"
              fontWeight={400}
            >
              {content.title}
            </Heading>
            <Text
              fontSize="0.875rem"
              lineHeight="1.125rem"
              color="white.60"
              fontWeight={500}
            >
              {content.description}
            </Text>
          </Flex>
        ))}
        {contents.length > itemsPerPage && (
          <Flex
            pos={isMobileAndOpen ? 'absolute' : 'relative'}
            bottom={isMobileAndOpen ? '0' : 'auto'}
            left={isMobileAndOpen ? '0' : 'auto'}
            flexDir="column"
            p="1rem"
            w="full"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
            roundedTop={isMobileAndOpen ? 'none' : '1.25rem'}
            bg={guideButtonGradient}
          >
            <Flex w="full" justifyContent="center" gap="0.25rem">
              {Array.from({ length: numberOfPages }).map((_, index) => (
                <Box
                  key={index}
                  w="0.75rem"
                  h="0.75rem"
                  rounded="full"
                  bg={currentPage === index + 1 ? 'white.60' : 'transparent'}
                  border="1px solid"
                  borderColor="white.60"
                />
              ))}
            </Flex>
            <Flex w="full" justifyContent="center" gap="1rem">
              {currentPage > 1 && (
                <Button
                  variant="garage-header"
                  w="3.75rem"
                  rounded="0.25rem"
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                  <IconArrow w="1.25rem" h="1.25rem" />
                </Button>
              )}
              <Button
                variant="guide-button"
                w="full"
                fontSize="1rem"
                lineHeight="1rem"
                fontWeight={400}
                onClick={() => {
                  if (currentPage === numberOfPages) {
                    setIsOpen(false);
                    setCurrentPage(1);
                  } else {
                    setCurrentPage((prev) => prev + 1);
                  }
                }}
              >
                {currentPage === numberOfPages ? "Let's Go!" : 'Next'}
              </Button>
            </Flex>
          </Flex>
        )}
      </MotionFlex>
    </MotionFlex>
  );

  return isMobileAndOpen && overlay
    ? // eslint-disable-next-line
      // @ts-ignore
      createPortal(guideContent, document.body)
    : guideContent;
};
