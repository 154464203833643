import { useQuery } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useGetCarUpgradeTrailerPrices = () => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const getCarUpgradeTrailerPricesFn = graphql(`
    query GetCarUpgradeTrailerPrices {
      getCarUpgradeTrailerPrices {
        price
        vextPrice
        maticPrice
        usdcPrice
        readableUsdcPrice
        readableMaticPrice
      }
    }
  `);

  return useQuery({
    queryKey: ['car-upgrade-trailer-prices'],
    enabled: Boolean(hasToken),
    queryFn: async () => {
      return await graphQLClient.request(getCarUpgradeTrailerPricesFn);
    },
  });
};

export default useGetCarUpgradeTrailerPrices;
