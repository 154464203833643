import {
  Button,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Text,
  Flex,
  Skeleton,
  useDisclosure,
  Box,
  Divider,
  Heading,
  Grid,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

import routes from 'routes';
import {
  IconLogout,
  IconTicket,
  IconVextBorder,
  IconGear,
  IconPencil,
  IconPlusCircle,
  IconChevron,
  IconDollarBalance,
  IconMaticCircle,
  IconTicketTournament,
} from 'icons';
import { colors } from 'utils/themeConstants';
import { useGetTicketsAvailable, useSessionData, useUserSettings } from 'hooks';
import { SVGIcon } from 'types';
import { RTicketsBundleModal, RTooltip } from 'components';
import { useUser, useWallet } from 'context';
import { WalletDrawer } from 'components/Wallet/WalletDrawer';
import SetAvatarNameModal from 'components/Avatar/SetAvatarNameModal';
import { useFunds } from 'context/fundsContext';
import { getNextDay3PMEST } from 'utils/helpers';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';

const Balance = ({
  Icon,
  color,
  amount,
  label,
  onClick,
}: {
  Icon: SVGIcon;
  color?: string;
  amount?: number | string;
  label?: string;
  onClick?: () => void;
}) => {
  return (
    <RTooltip label={label} shouldWrapChildren>
      <Flex h="full" alignItems="center" gap="1" fontSize="1rem">
        <Icon
          h="1.5rem"
          w="auto"
          mr="0.25rem"
          __css={{ path: { fill: color } }}
        />{' '}
        {/* {typeof amount === 'number' ? (
          <Text color={color} fontWeight={700}>
            {amount}
          </Text>
        ) : <Spinner size="xs" />} */}
        <Text color={color} fontWeight={700}>
          {amount}
        </Text>
        {onClick && (
          <IconPlusCircle
            h="0.875rem"
            w="auto"
            ml="0.25rem"
            cursor="pointer"
            __css={{ path: { fill: 'white.40' } }}
            onClick={onClick}
          />
        )}
      </Flex>
    </RTooltip>
  );
};

// const CustomButton = () => {
//   const { isOpen } = usePopoverContext();

//   return (
//     <Button
//       display="flex"
//       justifyContent="center"
//       variant="unstyled"
//       rightIcon={dropdownIcon}
//       bg={isOpen ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)'}
//       _hover={{
//         bg: 'rgba(255, 255, 255, 0.2)',
//       }}
//       borderRadius="3"
//       h={["2.5rem", "3rem"]}
//       p="2"
//     >
//       <Box
//         position="relative"
//         overflow="hidden"
//         borderRadius="3"
//         width="2.5rem"
//         height="2.5rem"
//         bg={`linear-gradient(362.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%), linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.yankeesBlue[100]} 100%)`}
//       >
//         <Avatar
//           size="full"
//           src={avatarImgSrc}
//           position="absolute"
//           w="5rem"
//           h="5rem"
//           left="-1.2rem"
//           objectFit="cover"
//           objectPosition="center"
//         />
//         <IconCrown width="1rem" top="-2.5" position="absolute" />
//       </Box>
//     </Button>
//   );
// };

const UserMenu = ({
  dropdownIcon,
  setIsWalletOpen,
}: {
  dropdownIcon: SVGIcon;
  setIsWalletOpen: (isOpen: boolean) => void;
}) => {
  const { clearSessionData } = useSessionData();
  const { getUserSettings } = useUserSettings();
  const { avatarImgSrc } = useUser();
  const { handleClearWalletData } = useWallet();

  const router = useRouter();
  const { data } = useGetTicketsAvailable();
  const { isOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEditName,
    onOpen: onOpenEditName,
    onClose: onCloseEditName,
  } = useDisclosure();
  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();

  const handleLogout = () => {
    clearSessionData();
    handleClearWalletData();
    router.push(routes.login());
  };

  const {
    isOpen: isWalletOpen,
    onClose: onWalletClose,
    onOpen: onWalletOpen,
  } = useDisclosure();

  const getDisplayName = () => {
    if (getUserSettings.isLoading) {
      return <Skeleton width="60%" mr="2" />;
    }

    return (
      <Text fontSize="md" whiteSpace="break-spaces" isTruncated>
        {getUserSettings.data?.getUser.playerName ||
          getUserSettings.data?.getUser.email.split('@')[0]}
      </Text>
    );
  };

  const { balanceData, isLoading: isFundsLoading } = useFunds();

  return (
    <>
      <SetAvatarNameModal
        isOpen={isOpenEditName}
        onClose={onCloseEditName}
        currentName={
          getUserSettings.data?.getUser.playerName ||
          getUserSettings.data?.getUser.email?.split('@')[0] ||
          '' // TODO show skeleton while loading
        }
      />
      <Popover
        arrowShadowColor="fieryCoral.100"
        offset={[0, 15]}
        isOpen={isPopoverOpen}
        onClose={onPopoverClose}
      >
        <WalletDrawer
          isOpen={isWalletOpen}
          onClose={() => {
            onWalletClose();
            setIsWalletOpen(false);
          }}
        />
        <Flex flexDir="row" alignItems="center" gap="5">
          {/* <Show above="md">
            <Balances />
          </Show> */}

          <Box>
            <PopoverTrigger>
              <Button
                display="flex"
                justifyContent="center"
                variant="unstyled"
                rightIcon={dropdownIcon}
                bg={
                  isPopoverOpen ? 'bloodMoon.100' : 'rgba(255, 255, 255, 0.1)'
                }
                _hover={{
                  bg: isPopoverOpen
                    ? 'bloodMoon.100'
                    : 'rgba(255, 255, 255, 0.2)',
                }}
                borderRadius="3"
                h={['2.5rem', '2.5rem']}
                p="1"
                onClick={onPopoverOpen}
                id="profile-button"
              >
                <Box
                  position="relative"
                  overflow="hidden"
                  borderRadius="3"
                  width="2rem"
                  height="2rem"
                  bg={`linear-gradient(362.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%), linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.yankeesBlue[100]} 100%)`}
                >
                  <Avatar
                    size="full"
                    src={avatarImgSrc}
                    position="absolute"
                    w="5rem"
                    h="4.5rem"
                    left="-1.5rem"
                    objectFit="cover"
                    objectPosition="center"
                  />
                  {/* <IconCrown width="1rem" top="-2.5" position="absolute" /> */}
                </Box>
              </Button>
            </PopoverTrigger>
          </Box>
        </Flex>

        <Portal>
          <PopoverContent
            marginRight="1.5rem"
            border="0"
            borderRadius="0"
            borderTop="solid 1px"
            borderBottom="solid 1px"
            borderColor="fieryCoral.100"
            width={{ base: '100vw', md: '20rem' }}
          >
            <PopoverArrow
              bg="fieryCoral.100"
              clipPath="polygon(100% 0%, 100% 0%, 0% 100%, 0% 0%)" // hides overflow that leaks through semi transparent background
              transform="scale(175%) rotate(45deg) !important"
            />
            <PopoverBody
              bg="darkVoid.100"
              p="4"
              boxShadow="dark-lg"
              backdropFilter="blur(10px)"
              w="full"
            >
              <Flex flexDir="column" gap="2">
                <Flex flexDir="column" alignItems="center">
                  <Flex
                    alignItems="center"
                    as={NextLink}
                    href={routes.avatar()}
                    flexDir="column"
                  >
                    <Box
                      position="relative"
                      borderRadius="3"
                      overflow="hidden"
                      width="6rem"
                      height="6rem"
                      mr="0.5rem"
                      mb="2"
                      bg={
                        'linear-gradient(123.99deg, rgba(79, 197, 186, 0) -31.65%, rgba(79, 197, 186, 0) 50.56%), linear-gradient(302.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%)'
                      }
                    >
                      <Avatar
                        size="full"
                        src={avatarImgSrc}
                        position="absolute"
                        w="12rem"
                        h="12rem"
                        left="-2.75rem"
                        objectFit="cover"
                        objectPosition="center"
                      />
                      {/* <IconCrown width="2rem" top="5" /> */}
                    </Box>
                  </Flex>
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={onOpenEditName}
                    id="edit-name-div"
                    gap={2}
                  >
                    {getDisplayName()}

                    <IconPencil width="1rem" ml="auto" />
                  </Flex>
                </Flex>

                <Divider my="4" />

                <Flex
                  width="full"
                  justifyContent="space-between"
                  flexDir="column"
                  px="2"
                  py="2"
                  cursor="pointer"
                  mb="4"
                  id="wallet-div"
                >
                  <Flex
                    alignItems="center"
                    pb="3"
                    onClick={() => {
                      onWalletOpen();
                      setIsWalletOpen(true);
                    }}
                  >
                    <Heading as="h2" size="xs" color="white.80">
                      WALLET
                    </Heading>
                    <IconChevron width="1rem" ml="auto" />
                  </Flex>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap="2"
                    rowGap="4"
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                    color="white.80"
                  >
                    {isFundsLoading && (
                      <>
                        <Skeleton width="60%" mr="2" mb="2" flex="1" />
                        <Skeleton width="60%" mr="2" mb="2" flex="1" />
                        <Skeleton width="60%" mr="2" mb="2" flex="1" />
                      </>
                    )}
                    {!isFundsLoading && (
                      <>
                        <Balance
                          label="USDC Balance"
                          Icon={IconDollarBalance}
                          amount={balanceData.usdc.readableBalance.toFixed(2)}
                          color="usdc.100"
                        />
                        <Balance
                          label="VEXT Balance"
                          Icon={IconVextBorder}
                          amount={Number(balanceData.vext.readableBalance) % 1 !== 0 
                            ? balanceData.vext.readableBalance.toFixed(4)
                            : balanceData.vext.readableBalance.toFixed(2)}
                          color="stargateShimmer.100"
                        />
                        <Balance
                          label="MATIC Balance"
                          Icon={IconMaticCircle}
                          amount={Number(balanceData.matic.readableBalance) % 1 !== 0 
                            ? balanceData.matic.readableBalance.toFixed(4)
                            : balanceData.matic.readableBalance.toFixed(2)}
                          color="matic.100"
                        />
                      </>
                    )}
                  </Grid>
                </Flex>
                <Flex
                  width="full"
                  justifyContent="space-between"
                  flexDir="column"
                  px="2"
                  py="2"
                  cursor="pointer"
                  onClick={() => router.push('/marketplace/tickets')}
                >
                  <Flex alignItems="center" mb="2">
                    <Heading as="h2" size="xs" color="white.80">
                      TICKETS
                    </Heading>
                    <IconPlusCircle width="1rem" ml="auto" />
                  </Flex>
                  <Flex gap="1rem" color="bloodMoon.100">
                    <Balance
                      label="Racino Tickets"
                      Icon={IconTicket}
                      amount={data?.ticketsAvailable?.ticketsAvailable}
                    />
                    <Flex gap={2}>
                      <Balance
                        label="Tournament Tickets"
                        Icon={IconTicketTournament}
                        amount={
                          data?.ticketsAvailable?.tournamentTicketsAvailable
                        }
                      />
                      <CountdownTimer
                        timeLeft={getNextDay3PMEST()}
                        color="white.80"
                        p="1px 2px"
                        fontSize="12px"
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <RTicketsBundleModal isOpen={isOpen} onClose={onClose} />

                <Divider my="4" />

                <Flex flexDir="column" gap="2">
                  <Button
                    onClick={() => router.push(routes.settings())}
                    variant="ghost"
                    width="full"
                    alignItems="center"
                    justifyContent="left"
                    position="relative"
                    flexDir="row"
                    size="sm"
                    leftIcon={<IconGear mx="1" />}
                    id="settings-button"
                  >
                    Settings
                    <IconChevron mx="1" position="absolute" right="3" />
                  </Button>

                  <Button
                    onClick={handleLogout}
                    variant="ghost"
                    width="full"
                    alignItems="center"
                    justifyContent="left"
                    flexDir="row"
                    size="sm"
                    leftIcon={<IconLogout mx="1" />}
                    id="logout-button"
                  >
                    Log Out
                  </Button>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default UserMenu;
