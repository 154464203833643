import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetRaceResultsById = (raceId: string) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  // TODO prune unused fields
  const getRaceResultsGql = graphql(`
    query GetRaceResultsById($raceId: String!) {
      getRaceResults(raceId: $raceId) {
        id
        createdAt
        status
        playersParticipants {
          id
          user {
            email
          }

          tyres {
            id
            name
          }
          frontWing {
            id
            name
            description
            rankRequired
            lowSettingRange
            highSettingRange
            usage
          }
          rearWing {
            id
            name
            description
            rankRequired
            lowSettingRange
            highSettingRange
            usage
          }

          engineCooling {
            id
            name
            description
            rankRequired
            lowSettingRange
            highSettingRange
            usage
          }
          brakeCooling {
            id
            name
            description
            rankRequired
            lowSettingRange
            highSettingRange
            usage
          }
          car {
            id
            name
            livery
            points
            appliedLivery {
              id
              imageUrl
            }
          }
          qualifyingGridPosition
          position
          brakingScore
          coolingScore
          topSpeedScore
          lowSpeedScore
          highSpeedScore
          handlingScore
        }
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken && raceId),
    queryKey: ['raceResultsQuery', raceId],
    queryFn: async () => {
      return graphQLClient.request(getRaceResultsGql, {
        raceId,
      });
    },
  });
};

export default useGetRaceResultsById;
