import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { AuthResponse, UserAttributes, Credentials } from 'types';

export let cognitoUser: AmazonCognitoIdentity.CognitoUser | null = null;

export const getCognitoUser = (email: string) => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID || '',
    ClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID || '',
  });

  const userData = {
    Username: email,
    Pool: userPool,
  };

  return new AmazonCognitoIdentity.CognitoUser(userData);
};

const loginCognito = async (credentials: Credentials) => {
  // normalize casing to prevent "user doesn't exist" errors
  const email = credentials.email.toLowerCase();

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    {
      Username: email,
      Password: credentials.password,
    }
  );

  cognitoUser = getCognitoUser(email);

  return new Promise<AuthResponse>((resolve, reject) => {
    cognitoUser?.authenticateUser(authenticationDetails, {
      mfaRequired: (result) => {
        console.log('mfaRequired', result);
        reject({ message: result });
      },
      onSuccess: function (result) {
        cognitoUser?.getUserAttributes((err, sess) => {
          if (err) return reject(err);

          const userAttributes: UserAttributes = sess?.reduce((obj, att) => {
            (obj as unknown as Record<string, string>)[att.Name] = att.Value;
            return obj;
          }, {} as UserAttributes) as UserAttributes;

          console.log('userAttributes', userAttributes);
          if (userAttributes?.username) {
            userAttributes.username = userAttributes?.sub;
          }

          resolve({
            userAttributes,
            accessToken: result.getAccessToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          });
        });
      },
      onFailure: function (err) {
        console.log(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

const sendMfaCode = (MFACode: string) => {
  return new Promise<AuthResponse>((resolve, reject) => {
    cognitoUser?.sendMFACode(MFACode, {
      onSuccess: function (result) {
        cognitoUser?.getUserAttributes((err, sess) => {
          if (err) return reject(err);

          const userAttributes: UserAttributes = sess?.reduce((obj, att) => {
            (obj as unknown as Record<string, string>)[att.Name] = att.Value;
            return obj;
          }, {} as UserAttributes) as UserAttributes;

          if (userAttributes?.username) {
            userAttributes.username = userAttributes?.sub;
          }

          resolve({
            userAttributes,
            accessToken: result.getAccessToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          });
        });
      },
      onFailure: function (err) {
        console.log(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });
};

export { loginCognito, sendMfaCode };
