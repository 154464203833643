/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  UseDisclosureProps,
  ButtonGroup,
  ButtonProps,
  Alert,
  useToast,
  ToastId,
  useDisclosure,
  Heading
} from '@chakra-ui/react';
import { useState, ReactElement, useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  RaceTuningInput,
  Races,
  Cars,
  CurrencyType,
  AirTemperatureCategory,
  TrackTemperatureCategory,
  Tier,
  WeatherCategories,
  WindCategories,
  TarmacConditionCategories,
} from 'api/generated/graphql';
import { RDrawer, RLoadingAnimation, RToast, RAlert } from 'components';
import { Tyres, SetupSuggestions } from 'types';
import { IconCheckeredFlag, IconCheckWhite } from 'icons';
import {
  useTuningForRace,
  useEnterRace,
  useGetGarage,
  useGetRaceById,
  useRaceCountdown,
  useSetCarPart,
  useGetTicketsAvailable,
  useUserSettings,
  useSaveSavedSetups,
} from 'hooks';
import { useAnimatedTransition, useTuning } from 'context';
import DrawerPages from './DrawerPages';
import { racePreviewGradient } from 'utils/themeConstants';
import { TicketTypeSelect } from './_components/TicketTypeSelect';
import { RaceHeader } from './_components/RaceHeader';
import RaceConditions from 'components/Races2/RaceConditions/RaceConditions';
import FullRaceConditions from 'components/Races2/RaceConditions/FullRaceConditions';
import { useRouter } from 'next/router';
import { CarSelect } from 'components/RSelect/CarSelect';
import { TuningCarStats } from './_components/TuningCarStats';
import { isMobileDevice } from 'utils/helpers';

const NextButton = ({
  children,
  isDisabled,
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <Button
      w="full"
      isDisabled={isDisabled}
      textAlign="center"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

type RaceDrawerProps = {
  raceId: string;
  carId?: string;
};

type PanelIdentifiers = 'selectCar' | 'selectTyres';

type DrawerPanelsType = {
  [key in PanelIdentifiers]?: JSX.Element;
};

// TODO consider a context for child props
const RacePreviewDrawer = ({
  raceId = '',
  carId,
  isOpen = false,
  onClose = () => null,
}: UseDisclosureProps & RaceDrawerProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { getUserSettings } = useUserSettings();
  const { showAnimatedTransition } = useAnimatedTransition();
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  const {
    isOpen: isOpenAdmissionUse,
    onOpen: onOpenAdmissionUse,
    onClose: onCloseAdmissionUse,
  } = useDisclosure();

  // queries
  const { data: garageData, isLoading: isLoadingGarage } = useGetGarage();
  const {
    data: raceByIdData,
    isLoading: isLoadingRaceById,
    isSuccess: isSuccessRaceById,
  } = useGetRaceById({
    raceId,
  });
  const { data: getTicketsAvailabeData } = useGetTicketsAvailable();
  const racinoTicketsAvailable =
    getTicketsAvailabeData?.ticketsAvailable?.ticketsAvailable || 0;
  const vextTicketsAvailable =
    getTicketsAvailabeData?.ticketsAvailable?.vextTicketsAvailable || 0;
  const tournamentTicketsAvailable =
    getTicketsAvailabeData?.ticketsAvailable?.tournamentTicketsAvailable || 0;
  // mutations
  const { enterRace } = useEnterRace();
  const { tuningForRace } = useTuningForRace();
  const setCarPart = useSetCarPart();
  const { mutateAsync: saveSavedSetups, isLoading: isLoadingSave } =
    useSaveSavedSetups();

  // data prep
  const race = raceByIdData?.getRaceById?.race as Races;
  const raceTier = race?.tier?.name?.toLowerCase();
  const isTournament = raceTier === Tier.Tournament;

  const ticketsAvailable = isTournament
    ? tournamentTicketsAvailable
    : race?.currency === CurrencyType.Vext
    ? vextTicketsAvailable
    : racinoTicketsAvailable;
  const numberOfTickets = race?.numberOfTickets || 0;
  const rawSuggestions = race?.setupSuggestions;
  const setupSuggestions: SetupSuggestions =
    rawSuggestions && JSON.parse(rawSuggestions);
  const carsInGarage = garageData?.getGarage as Cars[];
  const { raceStatus } = useRaceCountdown({
    currentStatus: race?.status,
    startTime: race?.startTime,
  });
  const {
    handling,
    speed,
    fastTurns,
    slowTurns,
    braking,
    cooling,
    winnerDiffs,
    isLoadingTuning,
    setCarId: setTuningCarId,
  } = useTuning();

  const userRaceData = race?.playersParticipants?.find((player) => {
    const isPlayerMatch = player.user.id === getUserSettings.data?.getUser.id;
    const isCarMatch = !carId || player.car.id === carId;

    return isPlayerMatch && isCarMatch;
  });

  // state
  const [panel, setPanel] = useState<PanelIdentifiers | null>(null);
  const [selectedCarId, setSelectedCarId] = useState<string | undefined>(
    userRaceData?.car?.id || carsInGarage?.[0]?.id
  );
  const [selectedTyres, setSelectedTyres] = useState<Tyres>(
    userRaceData?.tyres?.id as Tyres
  );
  const [withLeaderboardEntry, setWithLeaderboardEntry] = useState(false);
  const selectedCar = carsInGarage?.find((car) => car.id === selectedCarId);

  const [tuningSelections, setTuningSelections] = useState<RaceTuningInput>({
    raceId,
    carId: selectedCarId || '',
  });

  useEffect(() => {
    if (selectedCarId) {
      setTuningCarId(selectedCarId);
      setTuningSelections((oldState) => ({
        ...oldState,
        carId: selectedCarId,
      }));
    }
  }, [selectedCarId]);

  useEffect(() => {
    if (userRaceData?.tyres?.id) {
      setSelectedTyres(userRaceData?.tyres?.id as Tyres);
    }
  }, [userRaceData]);

  useEffect(() => {
    setSelectedCarId(userRaceData?.car.id || carsInGarage?.[0]?.id);
    setTuningSelections((oldState) => ({
      ...oldState,
      frontWingTuning: userRaceData?.frontWingTuning,
      rearWingTuning: userRaceData?.rearWingTuning,
      engineModeTuning: userRaceData?.engineModeTuning,
      engineCoolingTuning: userRaceData?.engineCoolingTuning,
      brakeCoolingTuning: userRaceData?.brakeCoolingTuning,
      transmissionTuning: userRaceData?.transmissionTuning,
      suspensionStiffnessTuning: userRaceData?.suspensionStiffnessTuning,
      suspensionRideHeightTuning: userRaceData?.suspensionRideHeightTuning,
    }));
  }, [userRaceData]);

  const saveLastUsedSetup = async () => {
    if (!tuningSelections) return;

    const setupParts = {
      frontWingTuning: tuningSelections?.frontWingTuning,
      rearWingTuning: tuningSelections?.rearWingTuning,
      brakeCoolingTuning: tuningSelections?.brakeCoolingTuning,
      engineCoolingTuning: tuningSelections?.engineCoolingTuning,
      transmissionTuning: tuningSelections?.transmissionTuning,
      engineModeTuning: tuningSelections?.engineModeTuning,
      suspensionStiffnessTuning: tuningSelections?.suspensionStiffnessTuning,
      suspensionRideHeightTuning: tuningSelections?.suspensionRideHeightTuning,
      tyresId: selectedTyres,
    };

    await saveSavedSetups({
      SavedSetupsInput: {
        name: 'Last Used',
        carId: selectedCarId || '',
        ...setupParts,
      },
    });
  };

  // display any errors while joining race
  const [raceAlert, setRaceAlert] = useState<
    ReactElement<typeof Alert> | undefined
  >(undefined);
  const [userInRace, setUserInRace] = useState<boolean | null>(null);

  const canEnterRace =
    !userInRace && (raceStatus === 'open' || raceStatus === 'tuning');
  const canTune =
    userInRace && (raceStatus === 'open' || raceStatus === 'tuning');

  const isMobile = isMobileDevice()
  
  const handleEnterRaceErrors = () => {
    const enterRaceError = enterRace?.error as { message: string };

    const formatErrorMessage = (errorMessage: string) => {
      const braceIndex = errorMessage.indexOf('{');
      if (braceIndex > -1) {
        return errorMessage.substring(0, braceIndex - 2);
      }

      return errorMessage;
    };

    const errorMessage: string | undefined = enterRaceError?.message
      ? formatErrorMessage(enterRaceError.message)
      : undefined;

    const isCarScheduledError =
      enterRaceError && errorMessage?.startsWith('Car already in other race');

    const isOtherRaceError = enterRaceError && !isCarScheduledError;

    if (!raceAlert && isCarScheduledError) {
      setRaceAlert(
        <RAlert
          variant="warning"
          description="Car already scheduled to race. After completion, you can enter another race."
        />
      );
      return true;
    }

    if (!raceAlert && isOtherRaceError) {
      if (errorMessage)
        setRaceAlert(<RAlert variant="error" description={errorMessage} />);
      else
        setRaceAlert(
          <RAlert
            variant="error"
            description="There was an error entering the race."
          />
        );
      return true;
    }

    return false;
  };

  useEffect(() => {
    const hasError = handleEnterRaceErrors();

    console.log(hasError);

    if (hasError) {
      setPanel('selectCar');
    }
  }, [enterRace?.error]);

  useEffect(() => {
    if ((race?.userInRace && !isTournament) || carId) {
      setUserInRace(true);
      setPanel('selectTyres');
    }
    if (!race?.userInRace) {
      setUserInRace(false);
      setPanel('selectCar');
    }
  }, [race?.userInRace, isOpen]);

  const isEligibleForRace = () => {
    const difficulty = race?.difficulty || 0;
    const rarity = selectedCar?.rarity;
    if (
      (difficulty >= 2 && ['trial', 'common'].includes(rarity || '')) ||
      (difficulty >= 3 && rarity === 'uncommon') ||
      (difficulty >= 4 && rarity === 'rare') ||
      (difficulty >= 5 && rarity === 'epic')
    )
      return false;
    else return true;
  };

  const getPanelKey = (
    currentPanel: PanelIdentifiers,
    change: number
  ): PanelIdentifiers => {
    const flow: PanelIdentifiers[] = ['selectCar', 'selectTyres'];

    const currentIndex = flow.indexOf(currentPanel);

    let nextIndex = currentIndex + change;
    nextIndex = Math.max(0, Math.min(flow.length - 1, nextIndex));

    if (change > 0) {
      if (currentPanel === 'selectCar' && canTune) {
        return 'selectTyres';
      }
      if (currentPanel === 'selectCar' && ticketsAvailable >= numberOfTickets) {
        return 'selectTyres';
      }
    }
    if (change < 0) {
      if (currentPanel === 'selectTyres') {
        return 'selectCar';
      }
    }

    return flow[nextIndex];
  };

  const updatePanel = (change: number) => {
    if (!panel) return;

    const nextPanelKey = getPanelKey(panel, change);
    if (nextPanelKey) {
      document.getElementById('drawerBody')?.scrollTo(0, 0);
      setPanel(nextPanelKey);

      queryClient.refetchQueries({
        queryKey: ['ticketsAvailable'],
      });
    }
  };

  const nextPanel = () => updatePanel(1);

  const renderPanel = (
    isLoading: boolean,
    PanelComponent: (props: any) => JSX.Element,
    props: any
  ) =>
    isLoading ? (
      <Flex w="full" h="full" alignItems="center" justifyContent="center">
        <RLoadingAnimation />
      </Flex>
    ) : (
      <PanelComponent {...props} />
    );

  const allPanels = {
    selectCar: renderPanel(
      isLoadingGarage && enterRace.isLoading,
      DrawerPages.SelectCar,
      {
        admissionFee: numberOfTickets,
        cars: carsInGarage,
        raceAlert,
        race: race,
        raceStartTime: race?.startTime,
        isOpen: isOpenAdmissionUse,
        withLeaderboardAvailable:
          raceByIdData?.getRaceById?.withLeaderboardAvailable,
        withLeaderboardEntry,
        setWithLeaderboardEntry,
        setSelectedCarId,
        onClose: onCloseAdmissionUse,
      }
    ),
    selectTyres: renderPanel(
      isLoadingGarage || isLoadingRaceById,
      DrawerPages.SelectTyres,
      {
        selectedTyres,
        setSelectedTyres,
        carId: selectedCarId,
        mutateCarPart: setCarPart.mutate,
        tuningSelections,
        setTuningSelections,
        parts: userRaceData,
        mutateTuning: tuningForRace.mutate,
        tuningError: tuningForRace.error,
        setupSuggestions,
      }
    ),
  };

  let drawerPanels: DrawerPanelsType = allPanels;

  if (canTune) {
    drawerPanels = {
      selectTyres: allPanels.selectTyres,
    };
  }

  const isEnteringRace =
    selectedCarId &&
    raceId &&
    panel === 'selectCar' &&
    ticketsAvailable >= numberOfTickets;

  // if (
  //   enterRace.isSuccess &&
  //   enterRace.data?.enterRace.status === 'accepted' &&
  //   panel === 'selectCar'
  // ) {
  //   queryClient.refetchQueries({
  //     queryKey: ['nextUpRacesByTierAndStatusQuery'],
  //   });
  //   nextPanel();
  // }

  let buttonText = 'Next';
  if (panel === 'selectTyres') {
    buttonText = 'Ready to Race';
  }

  const numberOfTicketsAndTournament = withLeaderboardEntry ? numberOfTickets + 1 : numberOfTickets
  if (panel === 'selectCar') {
    buttonText = `Race Entry: ${numberOfTicketsAndTournament} Ticket${
      numberOfTicketsAndTournament > 1 ? 's' : ''
    }`;
  }

  let loadingText = '';
  if (enterRace.isLoading) {
    loadingText = 'Entering Race...';
  }

  if (
    panel === 'selectTyres' &&
    (setCarPart.isLoading ||
      tuningForRace.isLoading ||
      isLoadingGarage ||
      isLoadingSave)
  ) {
    loadingText = 'Saving Setup...';
  }

  return (
    <RDrawer
      drawerBodyProps={{
        p: 'auto',
        py: 4,
      }}
      drawerContentProps={{
        bg: 'transparent',
      }}
      drawerHeaderProps={{
        bg: racePreviewGradient,
        borderBottomRadius: '1.25rem',
        p: '1rem',
      }}
      closeButtonProps={{
        ml: {
          base: '0.5rem',
          md: 'auto',
        },
        backgroundColor: 'transparent',
      }}
      drawerFooterProps={{
        bg: racePreviewGradient,
        borderTop: 'none',
        borderTopRadius: '1.25rem',
        padding: isMobile ? '0.75rem 1rem 0.75rem' : 'auto',
      }}
      heading={<RaceHeader race={race} raceStatus={raceStatus} />}
      headingProps={{
        w: {
          base: 'full',
          md: 'auto',
        },
      }}
      headerContent={
        !isLoadingRaceById &&
        race && (
          <>
            {panel === 'selectCar' ? (
              <RaceConditions
                airTemperature={
                  race?.airTemperatureCategory as AirTemperatureCategory
                }
                trackTemperature={
                  race?.trackTemperatureCategory as TrackTemperatureCategory
                }
                weather={race?.weatherCategory || 'dry'}
                difficulty={race?.difficulty}
                showHover
              />
            ) : (
              <FullRaceConditions
                airTemperature={
                  race?.airTemperatureCategory as AirTemperatureCategory
                }
                trackTemperature={
                  race?.trackTemperatureCategory as TrackTemperatureCategory
                }
                weather={(race?.weatherCategory as WeatherCategories) || 'dry'}
                wind={(race?.windCategory as WindCategories) || 'calm'}
                roughness={race?.trackRoughnessCategory as string}
                tarmac={
                  race?.tarmacConditionCategory as TarmacConditionCategories
                }
                showHover
              />
            )}
          </>
        )
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setWithLeaderboardEntry(false);
        setPanel('selectCar');
      }}
      drawerBody={
        <Flex
          pos="relative"
          flexDirection="column"
          width="full"
          overflowY="auto"
          overflowX="hidden"
          className="grey-scrollbar"
        >
          {isLoadingRaceById ||
          isLoadingGarage ||
          userInRace === null ||
          !panel ? (
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
              <RLoadingAnimation />
            </Flex>
          ) : (
            drawerPanels[panel]
          )}
        </Flex>
      }
      drawerFooter={
        <>
          {panel === 'selectTyres' && (
            <Flex
              alignItems="center"
              justifyContent="center"
              w="full"
              mb={isMobile ? "0" : "4"}
              flexDirection="column"
              gap={2}
            >
              {selectedCar && (
                  <Heading
                    as="h1"
                    fontSize="1.125rem"
                    fontWeight={400}
                    textTransform="uppercase"
                    color="white.95"
                  >
                    {selectedCar.name}
                  </Heading>
              )}
              <TuningCarStats
                stats={{
                  handling,
                  speed,
                  fastTurns,
                  slowTurns,
                  braking,
                  cooling,
                }}
                winnerDiffs={winnerDiffs}
                isLoading={isLoadingTuning}
                isLocked={selectedCar?.tier?.rank === 0}
                px="0.5rem"
                pt="0.75rem"
                mb={{ base: '0.5rem', md: '0' }}
              />
            </Flex>
          )}

          {isSuccessRaceById && panel === 'selectCar' && (
            <TicketTypeSelect
              numberOfTickets={
                withLeaderboardEntry ? numberOfTickets + 1 : numberOfTickets
              }
              ticketsAvailable={ticketsAvailable}
              type={isTournament ? 'tournament' : 'racino'}
            />
          )}

          {isSuccessRaceById && (canEnterRace || canTune) && (
            <ButtonGroup w="full">
              <NextButton
                isLoading={Boolean(loadingText)}
                loadingText={loadingText}
                id="bottom-drawer-button"
                borderRadius="0.25rem"
                isDisabled={
                  isLoadingGarage ||
                  (panel === 'selectCar' && selectedCar?.locked) ||
                  !isEligibleForRace()
                }
                variant={panel === 'selectCar' ? 'tertiary' : 'primary'}
                onClick={async () => {
                  if (isEnteringRace) {
                    // clear any existing errors
                    setRaceAlert(undefined);

                    const res = await enterRace.mutateAsync({
                      enterRaceInput: {
                        carId: selectedCarId,
                        raceId,
                        withLeaderboardEntry,
                      },
                    });

                    if (res.enterRace.status === 'accepted') {
                      showAnimatedTransition({
                        Icon: IconCheckeredFlag,
                        title: 'race',
                        id: 'race-entered-success',
                        subtitle: 'entered',
                        color: 'bloodMoon.100',
                        bgColor:
                          'linear-gradient(81.73deg, #008A8A -122.13%, #000000 46.69%, #000000 57.14%, #D83832 212.04%)',
                        gradientColor: {
                          from: 'rgba(216, 56, 50, 0.2)',
                          to: 'rgba(216, 56, 50, 0)',
                        },
                      });
                      nextPanel();
                      setUserInRace(true);
                    }

                    queryClient.refetchQueries({
                      queryKey: ['nextUpRacesByTierAndStatusQuery'],
                    });
                  }

                  if (panel === 'selectTyres') {
                    await saveLastUsedSetup();
                    router.push(`/races/${race?.id}`);
                  }

                  // if (
                  //   panel === 'selectCar' &&
                  //   selectedCar?.tier?.name !== race?.tier?.name
                  // ) {
                  //   return setRaceAlert(
                  //     <RAlert
                  //       variant="error"
                  //       description="Cannot join this race: car rank is insufficient"
                  //     />
                  //   );
                  // }
                  if (panel === 'selectCar' && !isEnteringRace) {
                    if (race?.tier?.name?.toLowerCase() === 'tournament') {
                      return (toastIdRef.current = toast({
                        position: 'bottom-right',
                        render: () => (
                          <RToast
                            variant="error"
                            title={`You must have at least ${race.numberOfTickets} tournament ticket(s) to enter this race`}
                            onClose={() =>
                              toast.close(toastIdRef.current as ToastId)
                            }
                          />
                        ),
                      }));
                    }

                    onOpenAdmissionUse();
                    return;
                  }

                  if (!isEnteringRace && panel !== 'selectTyres') {
                    nextPanel();
                  }
                }}
              >
                {buttonText}
                {buttonText === 'Ready to Race' &&
                  <IconCheckWhite ml="0.4rem" mb="0.15rem"/>}
              </NextButton>
            </ButtonGroup>
          )}
        </>
      }
    />
  );
};

export default RacePreviewDrawer;