import { useQuery } from '@tanstack/react-query';
import { Status, Tier } from 'api/generated/graphql';

import { useGraphQLClient } from 'hooks';

const useGetNextUpRacesByTierAndStatus = ({
  refetchInterval,
  tier = [
    Tier.Free,
    Tier.Bronze,
    Tier.Silver,
    Tier.Gold,
    Tier.Platinum,
    Tier.Tournament,
  ],
  status = [
    Status.Open,
    Status.Tuning,
    Status.Closed,
    Status.Qualifying,
    Status.Live,
  ],
  difficulties = [0, 1, 2, 3, 4, 5],
}: {
  refetchInterval?: number;
  tier?: Tier[];
  status?: Status[];
  difficulties?: number[];
}) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetNextUpRacesByTier(
      $Tier: [tier!]
      $Status: [status!]
      $Difficulties: [Float!]
    ) {
      getRaces(
        filter: {
          status: $Status
          tier: $Tier
          difficulties: $Difficulties
          limit: 500
        }
      ) {
        id
        laps
        prizePool
        status
        userInRace

        airTemperatureCategory
        trackTemperatureCategory
        weatherCategory
        difficulty

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets
        currency

        tier {
          name
        }

        track {
          id
          frontendName
          codeName
          length
          description
          has2D
          has3D
        }

        playersParticipants {
          id

          car {
            id
          }

          user {
            id
          }
        }
      }
    }
  `);

  const getNextUpRacesByTierAndStatus = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['nextUpRacesByTierAndStatusQuery'],
    refetchInterval,
    queryFn: async () => {
      return graphQLClient.request(racesGql, {
        Tier: tier,
        Status: status,
        Difficulties: difficulties,
      });
    },
  });

  return getNextUpRacesByTierAndStatus;
};

export default useGetNextUpRacesByTierAndStatus;
