import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetRaceTips = (raceId: string, carId?: string) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getRaceTipsGql = graphql(`
    query GetRaceTips($raceId: String!, $carId: String) {
      getRaceTips(raceId: $raceId, carId: $carId)
    }
  `);

  const getRaceTips = useQuery({
    enabled: Boolean(hasToken && raceId),
    queryKey: ['raceTipsQuery', raceId],
    queryFn: async () => {
      return graphQLClient.request(getRaceTipsGql, {
        raceId: raceId,
        carId: carId,
      });
    },
  });
  return getRaceTips;
};

export default useGetRaceTips;
