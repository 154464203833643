import { Box, Tooltip } from "@chakra-ui/react"
import { raceRarity } from "components/RacePreviewDrawer/_components/RaceHeader"
import RTiers, { defaultColors } from "components/Tiers/Tiers"
import { IconPlusTiers } from "icons"
import { RarityTier } from "types"
import { rarityGradients } from "utils/themeConstants"

type RaceTierProps = {
  difficulty: 0 | 1 | 2 | 3 | 4 | 5;
  padding?: string;
  iconSize?: string;
  showPlus?: boolean;
}

const RaceTier = ({ difficulty, padding = '6px', iconSize = '18px', showPlus = true }: RaceTierProps) => {
  const capitalizedTier = (raceRarity[difficulty] || 'Free').charAt(0).toUpperCase() + (raceRarity[difficulty] || 'Free').slice(1);
  if (difficulty === 0) {
    difficulty = 1;
  }
  
  return (
    <Tooltip
    label={
      capitalizedTier === 'Free'
        ? 'Common+'
        : capitalizedTier === 'Legendary'
        ? 'Legendary'
        : `${capitalizedTier}+`
    }
    >
      <Box
        background={
          rarityGradients[
          raceRarity[difficulty] as keyof typeof rarityGradients
          ] || rarityGradients['common']
        }
        borderWidth="1px"
        borderColor="white.20"
        borderRadius="0.25rem"
        p={padding}
        position="relative"
      >
        {difficulty !== 5 && showPlus && (
          <IconPlusTiers 
            position="absolute"
            top="0.5"
            right="0.5"
            transform="translate(25%, -25%)"
            __css={{
              svg: {
                width: iconSize,
                height: iconSize,
              },
              path: {
                fill: defaultColors[raceRarity[difficulty] as keyof typeof defaultColors],
              },
            }}
          />
        )}
        <RTiers
          tier={
            raceRarity[difficulty]as RarityTier
          }
          tierType="rarity"
          __css={{
            svg: {
              width: iconSize,
              height: iconSize,
            },
          }}
        />
      </Box>
    </Tooltip>
  )
}

export default RaceTier;