import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetRaceByIdQuery } from 'api/generated/graphql';

import { useGraphQLClient } from 'hooks';

const useGetRaceById = ({
  raceId,
  ...props
}: { raceId: string } & Omit<
  UseQueryOptions<GetRaceByIdQuery, unknown, GetRaceByIdQuery, string[]>,
  'initialData'
>) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetRaceById($raceId: String!) {
      getRaceById(id: $raceId) {
        race {
          id
          laps
          prizePool
          status
          userInRace
          setupSuggestions
          currency
          difficulty

          createdAt
          executedAt
          tuningPeriodEndTime
          qualifyingAnnouncementTime
          startTime
          numberOfTickets

          airTemperatureCategory
          tarmacConditionCategory
          trackRoughnessCategory
          trackTemperatureCategory
          weatherCategory
          windCategory

          recordingUrl

          playersParticipants {
            id
            position
            qualifyingGridPosition
            status
            car {
              id
              name
              description {
                part
                description
                avgComparison
              }
              points
              earnings
              wins
              rarity
              locked

              stats {
                stats
                bars
                description
              }

              appliedLivery {
                id
                name
                imageUrl
              }
              livery
              tyres {
                id
              }
            }
            points
            earnings
            time
            user {
              countryFlag
              avatarId
              id
              email
              playerName
            }
            frontWingId
            frontWingTuning
            frontWing {
              id
              usage
              type {
                name
              }
              name
              lowSettingRange
              highSettingRange
            }
            rearWingTuning
            rearWing {
              id
              usage
              type {
                name
              }
              name
              lowSettingRange
              highSettingRange
            }
            brakeCoolingTuning
            brakeCooling {
              id
              usage
              type {
                name
              }
              name
              lowSettingRange
              highSettingRange
            }
            engineCoolingTuning
            engineCooling {
              id
              usage
              type {
                name
              }
              name
              lowSettingRange
              highSettingRange
            }
            engineModeTuning
            suspensionStiffnessTuning
            suspensionRideHeightTuning
            transmissionTuning

            tyres {
              name
              id
            }
          }

          tier {
            name
            rank
          }

          track {
            id
            frontendName
            codeName
            length
            description
            has2D
            has3D
          }
        }

        withLeaderboardAvailable {
          available
          car {
            id
          }
        }

        tunableFields {
          id
          name
          field
          lowSettingRange
          highSettingRange
          tunableForRace
        }
      }
    }
  `);

  const getRaces = useQuery({
    enabled: Boolean(hasToken && raceId),
    queryKey: ['raceByIdQuery', raceId],
    queryFn: async () => {
      return graphQLClient.request(racesGql, {
        raceId,
      });
    },
    cacheTime: 0, // Não cachear a query
    staleTime: 0, // Sempre considerar a query como "stale"
    ...props,
  });

  return getRaces;
};

export default useGetRaceById;
