import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckEnded = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M9.125 1.375c.684 0 1.25.566 1.25 1.25v6.25c0 .703-.566 1.25-1.25 1.25h-6.25c-.703 0-1.25-.547-1.25-1.25v-6.25c0-.684.547-1.25 1.25-1.25h6.25Zm-.879 3.516a.53.53 0 0 0 0-.762.53.53 0 0 0-.762 0l-2.109 2.11-.879-.86a.53.53 0 0 0-.762 0 .53.53 0 0 0 0 .762l1.25 1.25c.098.117.235.156.391.156.137 0 .273-.04.371-.156l2.5-2.5Z"
    />
  </svg>
));
export default SvgIconCheckEnded;
