import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGoldenModal = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={202}
    height={165}
    fill="none"
    viewBox="0 0 202 165"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)" opacity={0.8}>
      <path
        stroke="url(#b)"
        d="M74.931 157.149h15.631l15.631-15.63 7.815-7.816 59.918-59.918v-15.63l-2.605-2.606a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-6.513-6.513a7.369 7.369 0 0 1-10.421-10.42l-6.512-6.513a7.37 7.37 0 0 1-10.421-10.42l-2.605-2.606h-15.631"
      />
    </g>
    <g filter="url(#c)" opacity={0.6}>
      <path
        stroke="url(#d)"
        d="M76.055 157.149h15.63l15.631-15.63 7.816-7.816 59.918-59.918v-15.63l-2.606-2.606a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-6.513-6.513a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-6.513-6.513a7.37 7.37 0 0 1-10.421-10.42l-2.605-2.606h-15.631"
      />
    </g>
    <g filter="url(#e)" opacity={0.4}>
      <path
        stroke="url(#f)"
        d="M77.178 157.149h15.63l15.631-15.63 7.816-7.816 59.918-59.918v-15.63l-2.605-2.606a7.37 7.37 0 0 1-10.421-10.42l-6.513-6.513a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-6.513-6.513a7.37 7.37 0 0 1-10.421-10.42l-2.605-2.606h-15.631"
      />
    </g>
    <g filter="url(#g)" opacity={0.2}>
      <path
        stroke="url(#h)"
        d="M78.3 157.149h15.632l15.63-15.63 7.816-7.816 59.918-59.918v-15.63l-2.605-2.606a7.37 7.37 0 0 1-10.421-10.42l-6.513-6.513a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-6.513-6.513a7.368 7.368 0 0 1-10.42 0 7.368 7.368 0 0 1 0-10.42l-2.606-2.606h-15.63"
      />
    </g>
    <g filter="url(#i)">
      <path
        fill="url(#j)"
        fillRule="evenodd"
        d="m73.639 157.559-3.015-3.014.29-.29a6.958 6.958 0 1 0-9.842-9.841l-.29.289-7.091-7.092.29-.289a6.96 6.96 0 0 0-9.842-9.842l-.29.29-7.091-7.092.289-.29a6.958 6.958 0 0 0 0-9.841 6.958 6.958 0 0 0-9.842 0l-.29.289-3.014-3.014v-15.97l83.604-83.604h15.97l3.015 3.014-.29.29a6.96 6.96 0 0 0 9.842 9.841l.289-.289 7.092 7.092-.289.29a6.958 6.958 0 1 0 9.841 9.842l.29-.29 7.091 7.091-.289.29a6.96 6.96 0 0 0 9.842 9.842l.289-.29 3.015 3.014v15.97L89.609 157.56h-15.97ZM99.48 65.743c3.057-3.057 8.09-3.086 11.109-.067l-.01-.016c3.021 3.022 3.017 8.025-.068 11.11l-1.617 1.617 15.549 2.799 4.52-4.52-11.364-2.046c.223-.802.37-1.625.437-2.452.299-3.676-.941-7.435-3.733-10.227-5.049-5.05-13.398-5-18.511.113L67.017 90.828l-6.235-1.122L92.11 58.378c7.168-7.167 18.835-7.24 25.918-.157 3.349 3.35 5.103 7.693 5.273 12.103l10.181 1.833 1.124 6.238-8.439 8.44-21.787-3.92-2.887-4.47 5.342-5.342c1.023-1.023 1.035-2.692.024-3.703-1.01-1.01-2.674-1.004-3.703.024L79.499 93.082l-6.235-1.122L99.48 65.743Zm15.938 24.489 2.868 4.493-11.427 11.428-10.09-15.62-4.792 4.79-6.237-1.119 11.95-11.95 10.088 15.617 3.808-3.808-3.304-5.112 7.136 1.28Zm-11.274-43.888-.323.323 2.345 2.345.306-.307-1.711-1.711.007-.007 2.077.817.226-.227-.813-2.08.007-.007 1.711 1.712.307-.307-2.345-2.345-.323.324.981 2.448-.006.007-2.446-.985Zm-2.107 2.663.704-.703 2.066 2.067.321-.32-2.067-2.067.703-.704-.278-.278-1.727 1.727.278.279Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={17.619}
        x2={168.336}
        y1={99.837}
        y2={130.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={18.742}
        x2={169.459}
        y1={99.837}
        y2={130.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={19.865}
        x2={170.582}
        y1={99.837}
        y2={130.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={20.988}
        x2={171.705}
        y1={99.837}
        y2={130.937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={115.49}
        x2={146.628}
        y1={0.263}
        y2={152.224}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <filter
        id="a"
        width={107.495}
        height={157.492}
        x={70.931}
        y={4.157}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4951_28501"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4951_28501"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        width={107.495}
        height={157.492}
        x={72.055}
        y={4.157}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4951_28501"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4951_28501"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        width={107.495}
        height={157.492}
        x={73.178}
        y={4.157}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4951_28501"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4951_28501"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        width={107.495}
        height={157.492}
        x={74.301}
        y={4.157}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4951_28501"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4951_28501"
          result="shape"
        />
      </filter>
      <filter
        id="i"
        width={172.123}
        height={172.123}
        x={12.495}
        y={-3.158}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0 0.345098 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4951_28501"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4951_28501"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconGoldenModal;
