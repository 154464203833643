import { Flex, Box, useDisclosure } from '@chakra-ui/react';
import { RacesBackground } from 'components/RaceCard/RaceCard';
import Image from 'next/image';
import RaceMinimap from '../RaceMinimap';
import RImage from 'components/Image/Image';
import overlayGradient from 'public/images/races/race-thumb-overlay.png';
import { IconMagnifyingGlass } from 'icons';
import RaceThumbnailModal from './RaceThumbnailModal';

export const RaceThumbnail = ({
  trackName,
  difficulty,
  tier,
}: {
  trackName: string;
  difficulty: number;
  tier: 'race' | 'tournament';
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex
      w="100%"
      h="100%"
      position="relative"
      opacity={1}
      borderRadius="0.5rem"
    >
      <RImage
        src={overlayGradient.src}
        width="0" // width set in style property
        height="0" // height set in style property
        objectFit="cover"
        objectPosition="top left"
        blendMode="hard-light"
        borderRadius="0.5rem"
        style={{
          width: '100%',
          height: '100%',
          zIndex: 10,
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
        }}
        alt={'background image'}
      />

      <Image
        src={RacesBackground[trackName] || RacesBackground['Belgium']}
        alt="Race Background"
        style={{ objectFit: 'cover', opacity: 0.8, borderRadius: '0.5rem' }}
        fill
        sizes="(max-width: 768px) 100vw, 50vw"
      />

      <Box
        pos="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        margin="auto"
        zIndex={10}
      >
        <RaceMinimap race={trackName} height="100%" width="100%" opacity={1} />
      </Box>
      <Box
        pos="absolute"
        top="1"
        right="1"
        margin="auto"
        zIndex={10}
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        <IconMagnifyingGlass />
      </Box>

      <RaceThumbnailModal
        title={trackName}
        difficulty={difficulty}
        isOpen={isOpen}
        onClose={onClose}
        tier={tier}
      />
    </Flex>
  );
};
