import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { RImage, RPositionAndPoints, RTiers } from 'components';
import { TyreCard } from 'components/TyreList/TyreList';
import { IconList } from 'icons';
import {
  AppliedLivery,
  CarTier,
  Livery,
  RarityTier,
  SVGIcon,
  Tyres,
} from 'types';
import { rarityGradients, selectedTyreGradient } from 'utils/themeConstants';

interface PerformanceCarCardProps {
  vehicleName?: string;
  points?: number;
  carPosition?: number;
  livery?: Livery | undefined;
  tier?: CarTier;
  rarity?: RarityTier;
  appliedLivery?: AppliedLivery;
  started?: string;
  finished?: string;
  icon: SVGIcon;
}

const RPerformanceCarCard = ({
  vehicleName,
  points,
  livery,
  appliedLivery,
  carPosition,
  started,
  finished,
  icon: Icon,
  rarity,
}: PerformanceCarCardProps) => {
  return (
    <Flex flexDir="column" p={{ base: 2, md: 4 }}>
      <Flex flexDir="column" h="full">
        <Flex justifyContent="space-between" alignItems="flex-end">
          <Flex gap={2} flexDir="column">
            <Text fontFamily={'heading'} fontSize="1.5rem">
              {vehicleName}
            </Text>
            <Flex gap={2}>
              <Box
                background={
                  rarityGradients[rarity as keyof typeof rarityGradients] ||
                  rarityGradients['Free']
                }
                borderWidth="1px"
                borderColor="white.20"
                borderRadius="0.25rem"
                p="6px"
              >
                <RTiers
                  tier={rarity as RarityTier}
                  tierType="rarity"
                  __css={{
                    svg: {
                      height: '14px',
                      width: '14px',
                    },
                  }}
                />
              </Box>

              <Flex alignItems="center" gap="2">
                <Box p="2" background="perrywinkle.100" borderRadius="8px">
                  <IconList
                    w="12px"
                    h="12px"
                    __css={{
                      path: {
                        fill: 'black.60',
                      },
                    }}
                  />
                </Box>
                <Text fontSize="12px" fontWeight="500" color="white.100">
                  {points} PTS
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            pos="relative"
            borderWidth="0.094rem"
            borderRadius="0.25rem"
            p="3"
            borderColor={'firstDaffodil.100'}
            alignItems="center"
            justifyContent="center"
            bg={selectedTyreGradient}
          >
            <Icon w="2.625rem" h="2.625rem" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        zIndex="1"
        p="4"
        justifyContent="center"
        w="10rem"
        m="0 auto"
        alignItems="center"
        h="full"
      >
        <RImage
          src={
            appliedLivery
              ? require(`public/images/cars/livery-${appliedLivery.imageUrl}.webp`)
              : require(`public/images/cars/livery-${livery}.webp`)
          }
          alt=""
          objectPosition="center center"
          position="absolute"
          zIndex="2"
          transform="scale(1.5)"
        />

        <RImage
          src={require(`public/images/cars/livery-shadow.webp`)}
          alt=""
          position="relative"
          objectPosition="center center"
          zIndex="0"
          filter="blur(2px)"
        />
      </Flex>
      <Flex flexDir="row" gap={2} py="2" justifyContent="space-between">
        <Flex justifyContent="space-between" alignItems="center" gap="3">
          <Text fontFamily={'heading'} fontSize="14px" color="white.80">
            STARTED
          </Text>
          <Text
            background={
              started === '1st' ? 'bloodMoon.100' : 'cerebralGrey.100'
            }
            fontSize="12px"
            p="0.5"
            px="1"
            fontWeight="700"
            textTransform="uppercase"
            textAlign="center"
            color={started === '1st' ? 'white.100' : 'black.60'}
            borderRadius="4px"
          >
            {started}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" gap="3">
          <Text fontFamily={'heading'} fontSize="0.875rem" color="white.60">
            FINISHED
          </Text>
          <Text
            background={started === '1st' ? 'bloodMoon.100' : 'white.100'}
            fontSize="12px"
            p="0.5"
            px="1"
            fontWeight="700"
            textTransform="uppercase"
            textAlign="center"
            color={started === '1st' ? 'white.100' : 'black.80'}
            borderRadius="4px"
          >
            {finished}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RPerformanceCarCard;
