import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPlusTiers = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={12}
    fill="none"
    viewBox="0 0 11 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M8.75 6a.517.517 0 0 1-.5.516H6v2.25a.5.5 0 0 1-.5.484.49.49 0 0 1-.5-.484v-2.25H2.75A.507.507 0 0 1 2.25 6a.49.49 0 0 1 .5-.484H5v-2.25c0-.282.219-.516.5-.516.266 0 .5.234.5.516v2.25h2.25a.49.49 0 0 1 .5.484Z"
    />
  </svg>
));
export default SvgIconPlusTiers;
